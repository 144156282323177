@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Ropa+Sans");
@import url("https://use.typekit.net/qne7ppa.css");
@import url(../fonts/icomoon/style.css);
body.showBlk * {
  background-color: rgba(255, 0, 0, 0.2); }

body.showBlk * * {
  background-color: rgba(0, 255, 0, 0.2); }

body.showBlk * * * {
  background-color: rgba(0, 0, 255, 0.2); }

body.showBlk * * * * {
  background-color: rgba(255, 0, 255, 0.2); }

body.showBlk * * * * * {
  background-color: rgba(0, 255, 255, 0.2); }

body.showBlk * * * * * * {
  background-color: rgba(255, 255, 0, 0.2); }

body.showBlk * * * * * * * {
  background-color: rgba(255, 0, 0, 0.2); }

body.showBlk * * * * * * * * {
  background-color: rgba(0, 255, 0, 0.2); }

body.showBlk * * * * * * * * * {
  background-color: rgba(0, 0, 255, 0.2); }

/* CSS Document */
*, :before, :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s linear; }

ul, ol {
  list-style: none; }

h1, h2, h3, h4, ul, ol, dl, dd, p, hr, figure {
  margin: 0;
  padding: 0; }

header, nav, main, footer, section {
  display: block; }

strong {
  font-weight: normal; }

form {
  margin-bottom: 0; }

img {
  vertical-align: bottom; }

.na {
  display: none !important; }

::selection {
  color: #fff;
  background: #00aebb; }

::-moz-selection {
  color: #fff; }

input::selection,
textarea::selection {
  color: #221814;
  background: #dbdcdc; }

.collection main {
  overflow: hidden; }

.noLink {
  text-decoration: none !important;
  cursor: default; }

p {
  line-height: 1.6;
  font-size: 16px;
  text-align: justify; }

.ar {
  text-align: right; }

i {
  font-style: normal; }

.fr {
  float: right; }

.idx {
  display: flex;
  justify-content: space-between; }

.note {
  padding-left: 1.2em;
  position: relative; }
  .note i {
    font-style: normal;
    position: absolute;
    top: 0;
    left: 0; }

body .test {
  display: none !important; }

html.test .test {
  display: block !important; }

html.test #crowari .test {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flex !important;
  display: -o-flex !important;
  display: flex !important; }

html.test .live {
  display: none !important; }

@media screen and (max-width: 767px) {
  img {
    height: auto; } }

/* !!------------------------------------ */
/* !! 共通css */
html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0;
  color: #3e3a39;
  background: #fff;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-family: "Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important; }

main {
  font-family: "Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important; }
  main > section {
    padding-top: 100px; }

/* !!------------------------------------ */
/* !! ヘッダー */
.header_logo_area .copy {
  font-size: 1.2rem !important; }

.header_logo_area img {
  width: 294px;
  height: auto;
  display: inline-block;
  vertical-align: middle; }

.header_logo_area span {
  display: inline-block;
  font-size: 1.3rem;
  vertical-align: middle;
  margin-left: 10px; }

#category_id {
  appearance: none !important;
  display: none !important; }

@media screen and (min-width: 768px) {
  ul.category-nav {
    max-width: 1118px;
    margin: 30px auto;
    height: 50px;
    border: 1px solid #000;
    border-width: 1px 0;
    font-size: 0;
    text-align: center; }
    ul.category-nav li {
      margin: 0 !important;
      display: inline-block; }
      ul.category-nav li a {
        font-size: 16px;
        padding: 0 15px; } }

.theme_main_only {
  overflow: hidden; }

/* !!------------------------------------ */
/* !! トップページ */
.slick-slide > img {
  width: 100% !important;
  height: auto !important; }

h1.hd,
h2 {
  font-size: 14px;
  font-family: "Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
  text-align: center; }
  h1.hd i,
  h2 i {
    font-size: 32px;
    font-family: urw-din-semi-condensed, sans-serif;
    font-weight: 300; }

#top-info {
  border: 1px solid #000;
  padding: 30px;
  opacity: .7;
  line-height: 1.7;
  margin: 0 auto;
  max-width: 700px;
  margin-top: 70px; }
  @media screen and (max-width: 767px) {
    #top-info {
      margin: 30px;
      margin-top: 60px;
      padding: 10px; } }

.top-info {
  background: #fffdc6;
  margin-top: 60px;
  border-radius: 4px;
  padding: 30px;
  text-align: center; }
  .top-info .inner {
    display: inline-block;
    text-align: justify; }
  .top-info h2 {
    font-size: 24px;
    letter-spacing: .1em;
    line-height: 1.5; }
  .top-info p {
    font-size: 16px;
    line-height: 1.7;
    margin-top: 1em; }
    @media screen and (max-width: 767px) {
      .top-info p {
        font-size: 3.738vw; } }
    @media screen and (min-width: 768px) {
      .top-info p {
        white-space: nowrap; } }
  .top-info p.cen {
    border: 1px solid rgba(102, 102, 102, 0.47);
    text-align: center;
    margin-top: 0;
    padding: 15px 0;
    letter-spacing: .1em; }
    .top-info p.cen strong {
      font-size: 1.2em;
      font-weight: bold; }
  .top-info p.note {
    padding-left: 1.4em;
    position: relative; }
    .top-info p.note i {
      font-style: normal;
      position: absolute;
      top: 0;
      left: 0; }
  .top-info.holiday {
    background: #ffebeb; }
    .top-info.holiday .date {
      background: #fff;
      text-align: center;
      padding: 10px 0;
      border-radius: 999px;
      letter-spacing: .1em; }

/* !! - - -  */
/* !! ランキング */
ul.rank {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  margin-top: 50px; }
  ul.rank li {
    width: 200px; }
    ul.rank li .number {
      font-family: urw-din-semi-condensed, sans-serif;
      font-weight: 600;
      text-align: center;
      font-size: 36px;
      display: block;
      line-height: 1;
      margin-bottom: 23px; }
    ul.rank li .img {
      height: 200px;
      background: #eee;
      position: relative;
      overflow: hidden;
      border: 1px solid #ddd; }
      ul.rank li .img img {
        width: 100% !important;
        height: auto !important; }
      ul.rank li .img:after {
        content: "";
        width: 28px;
        height: 28px;
        position: absolute;
        bottom: -14px;
        right: -14px;
        z-index: 1;
        background: #000;
        transform: rotate(45deg); }
    ul.rank li .ttl {
      font-size: 16px;
      font-weight: bold;
      padding: 8px 0; }
    ul.rank li .price {
      font-size: 14px;
      text-align: right;
      position: relative;
      height: 20px;
      line-height: 1;
      margin-bottom: 10px; }
      ul.rank li .price.teiki {
        color: #AF1E24; }
        ul.rank li .price.teiki b {
          display: inline-block;
          color: #fff;
          background: #AF1E24;
          line-height: 20px;
          padding: 0 5px;
          margin-right: 2px;
          vertical-align: top;
          font-size: 13px; }
        ul.rank li .price.teiki i {
          color: #AF1E24; }
      ul.rank li .price span {
        position: absolute;
        left: 0;
        top: 50%;
        line-height: 1;
        font-size: 13px;
        color: #3e3a39;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
      ul.rank li .price i {
        font-size: 1.36em;
        font-family: urw-din-semi-condensed, sans-serif;
        font-weight: 500; }
    ul.rank li .detail {
      display: block;
      margin-top: 15px;
      border: 1px solid #26B7BC;
      text-align: center;
      color: #26B7BC;
      line-height: 28px;
      position: relative;
      font-weight: bold; }
      ul.rank li .detail:after {
        display: block;
        position: absolute;
        top: 50%;
        left: auto;
        right: 10px;
        content: "";
        width: 6px;
        height: 6px;
        border: 1px solid #26B7BC;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(-45deg) translateY(-50%);
        -moz-transform: rotate(-45deg) translateY(-50%);
        -ms-transform: rotate(-45deg) translateY(-50%);
        -o-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: center center;
        left: auto;
        right: 10px; }
    ul.rank li:nth-of-type(1) .number {
      color: #947F28; }
    ul.rank li:nth-of-type(1) .img:after {
      background: #947F28; }
    ul.rank li:nth-of-type(2) .number {
      color: #727171; }
    ul.rank li:nth-of-type(2) .img:after {
      background: #727171; }
    ul.rank li:nth-of-type(3) .number {
      color: #955E29; }
    ul.rank li:nth-of-type(3) .img:after {
      background: #955E29; }
    ul.rank li:nth-of-type(4) .number {
      color: #8AA3D4; }
    ul.rank li:nth-of-type(4) .img:after {
      background: #8AA3D4; }

/* !! - - -  */
/* !! クロワール */
.btn2 {
  background: #EA5532;
  display: block;
  height: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 18px;
  box-shadow: 0 2px 0px #bc3313;
  color: #FFF !important;
  width: 160px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .1em;
  margin: 0 auto;
  margin-top: 15px !important;
  position: relative;
  transition: 100ms; }
  .btn2:hover {
    box-shadow: 0 1px 0px #bc3313;
    top: 1px;
    color: #Fff; }
  .btn2:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: auto;
    right: 10px;
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    -moz-transform: rotate(-45deg) translateY(-50%);
    -ms-transform: rotate(-45deg) translateY(-50%);
    -o-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: center center; }

section#product .slide .cell, main.product #croire-list.croire .cell {
  position: relative;
  transition: 300ms cubic-bezier(0, 0.74, 0.38, 0.96);
  position: relative;
  width: 100%;
  height: 350px;
  display: inline-block;
  position: relative;
  padding: 40px; }
  section#product .slide .cell > a.cover, main.product #croire-list.croire .cell > a.cover {
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  section#product .slide .na.cell, main.product #croire-list.croire .na.cell {
    display: block !important; }
  .test section#product .slide #probiotics12.cell, section#product .slide .test #probiotics12.cell, .test main.product #croire-list.croire #probiotics12.cell, main.product #croire-list.croire .test #probiotics12.cell {
    display: block; }
  section#product .slide .croire-coat.cell p._2 img, main.product #croire-list.croire .croire-coat.cell p._2 img {
    display: none; }
  @media screen and (min-width: 768px) {
    section#product .slide .croire-coat.cell .price, main.product #croire-list.croire .croire-coat.cell .price {
      position: relative;
      top: -17px; } }
  #page_user_data section#product .slide #probiotics12.cell .pb12, section#product .slide #page_user_data #probiotics12.cell .pb12, #page_user_data main.product #croire-list.croire #probiotics12.cell .pb12, main.product #croire-list.croire #page_user_data #probiotics12.cell .pb12 {
    display: block;
    padding-top: 12px;
    font-size: 13px; }
  section#product .slide .cell .pb12, main.product #croire-list.croire .cell .pb12 {
    display: none; }
  section#product .slide .cell .inner, main.product #croire-list.croire .cell .inner {
    position: relative;
    padding-left: 220px;
    width: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    section#product .slide .cell .inner .img, main.product #croire-list.croire .cell .inner .img {
      width: 180px;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      section#product .slide .cell .inner .img img, main.product #croire-list.croire .cell .inner .img img {
        width: 100% !important;
        height: auto !important; }
  section#product .slide .cell > img, main.product #croire-list.croire .cell > img {
    width: 100% !important;
    height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: auto !important; }
  section#product .slide .cell .ttl, main.product #croire-list.croire .cell .ttl {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    line-height: 1; }
    section#product .slide .cell .ttl i.cont, main.product #croire-list.croire .cell .ttl i.cont {
      font-size: 0.67em;
      display: inline-block;
      margin-left: 10px; }
      @media screen and (max-width: 767px) {
        section#product .slide .cell .ttl i.cont, main.product #croire-list.croire .cell .ttl i.cont {
          display: block;
          margin-left: 0; } }
    section#product .slide .cell .ttl span, main.product #croire-list.croire .cell .ttl span {
      font-size: 14px;
      margin: 8px 0;
      font-weight: normal;
      display: block;
      line-height: 1.5; }
  section#product .slide .cell .base_price p, main.product #croire-list.croire .cell .base_price p {
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    line-height: 1;
    margin: 20px 0; }
    @media screen and (max-width: 767px) {
      section#product .slide .cell .base_price p, main.product #croire-list.croire .cell .base_price p {
        display: block !important; }
        section#product .slide .cell .base_price p span, main.product #croire-list.croire .cell .base_price p span {
          display: block;
          width: 100%;
          margin-bottom: 6px; } }
    section#product .slide .cell .base_price p i, main.product #croire-list.croire .cell .base_price p i {
      font-size: 22px;
      padding: 0 5px; }
      @media screen and (max-width: 767px) {
        section#product .slide .cell .base_price p i, main.product #croire-list.croire .cell .base_price p i {
          padding: 0; } }
  section#product .slide .cell ul, main.product #croire-list.croire .cell ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    section#product .slide .cell ul li, main.product #croire-list.croire .cell ul li {
      position: relative;
      width: 300px;
      padding-bottom: 15px; }
      section#product .slide .cell ul li .hd, main.product #croire-list.croire .cell ul li .hd {
        line-height: 30px !important;
        text-align: center;
        background: #26B7BC;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: .1em; }
      section#product .slide .cell ul li .txt, main.product #croire-list.croire .cell ul li .txt {
        padding: 15px;
        font-size: 14px;
        font-weight: bold;
        color: #B81C22; }
      section#product .slide .cell ul li .price, main.product #croire-list.croire .cell ul li .price {
        padding: 0px;
        white-space: nowrap;
        text-align: center; }
        section#product .slide .cell ul li .price p, main.product #croire-list.croire .cell ul li .price p {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          white-space: nowrap;
          align-items: center;
          justify-content: center;
          height: 28px; }
          section#product .slide .cell ul li .price p img, main.product #croire-list.croire .cell ul li .price p img {
            margin-right: 10px;
            height: auto; }
      section#product .slide .cell ul li p._1, main.product #croire-list.croire .cell ul li p._1 {
        font-size: 13px;
        font-weight: bold; }
        section#product .slide .cell ul li p._1 i, main.product #croire-list.croire .cell ul li p._1 i {
          font-size: 1.5em; }
      section#product .slide .cell ul li p._2, main.product #croire-list.croire .cell ul li p._2 {
        font-size: 18px;
        font-weight: bold;
        color: #B81C22;
        vertical-align: top;
        position: relative;
        top: -3px; }
        section#product .slide .cell ul li p._2 i, main.product #croire-list.croire .cell ul li p._2 i {
          font-family: urw-din-semi-condensed, sans-serif;
          font-size: 40px; }
        section#product .slide .cell ul li p._2 img, main.product #croire-list.croire .cell ul li p._2 img {
          height: auto;
          width: auto; }
      section#product .slide .cell ul li.teiki, main.product #croire-list.croire .cell ul li.teiki {
        border-bottom: 4px solid #26B7BC; }
      section#product .slide .cell ul li.onestop, main.product #croire-list.croire .cell ul li.onestop, section#product .slide .cell ul li.try, main.product #croire-list.croire .cell ul li.try {
        border-bottom: 4px solid #9fa0a0; }
        section#product .slide .cell ul li.onestop .hd, main.product #croire-list.croire .cell ul li.onestop .hd, section#product .slide .cell ul li.try .hd, main.product #croire-list.croire .cell ul li.try .hd {
          background: #9fa0a0; }

section#product .slide {
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  max-width: 960px; }
  section#product .slide .slick-prev, section#product .slide .slick-next {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -50px;
    width: 80px;
    height: 80px;
    background: #000;
    border: none;
    font-size: 0;
    appearance: none;
    background: none;
    box-shadow: none;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    section#product .slide .slick-prev:after, section#product .slide .slick-next:after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      border: 7px solid #ccc;
      border-width: 0 0 7px 7px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -moz-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      -o-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg); }
  section#product .slide .slick-next {
    left: auto;
    right: -50px; }
    section#product .slide .slick-next:after {
      content: "";
      border-width: 7px 7px 0 0; }
  section#product .slide .cell:hover {
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02); }
  section#product .slide .cell ul a {
    display: none !important; }

/* !! - - -  */
/* !! クロ割 */
#crowari h2 img {
  width: 100% !important;
  height: auto !important; }

#crowari ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  max-width: 890px;
  justify-content: space-between;
  margin: 50px auto; }

.btn-more {
  margin: 0 auto;
  display: block;
  width: 300px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #26B7BC;
  text-align: center;
  color: #26B7BC;
  font-weight: bold;
  letter-spacing: .1em;
  font-size: 16px;
  line-height: 46px;
  position: relative; }
  .btn-more:after {
    display: block;
    position: absolute;
    top: 50%;
    left: auto;
    right: 10px;
    content: "";
    width: 8px;
    height: 8px;
    border: 1px solid #26B7BC;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    -moz-transform: rotate(-45deg) translateY(-50%);
    -ms-transform: rotate(-45deg) translateY(-50%);
    -o-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: center center;
    left: auto;
    right: 20px; }

/* !! - - -  */
/* !! マガジン */
ul.magazine {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  width: 100%;
  margin: 50px auto;
  position: relative;
  margin-bottom: 100px; }
  ul.magazine:after {
    width: 100vw;
    content: "";
    height: 250px;
    position: absolute;
    z-index: 0;
    bottom: -50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #F5F3F2; }
  ul.magazine li {
    width: 300px;
    position: relative;
    z-index: 1;
    background: #fff;
    padding-bottom: 30px; }
    ul.magazine li a {
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    ul.magazine li .img {
      height: auto;
      background: #eee; }
      ul.magazine li .img img {
        width: 100% !important;
        height: auto !important; }
    ul.magazine li .ttl {
      font-size: 16px;
      padding: 20px;
      line-height: 1.5;
      margin-top: -0.25em;
      margin-bottom: -0.25em;
      font-weight: bold;
      letter-spacing: .1em; }
    ul.magazine li .date {
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      line-height: 1;
      font-size: 12px;
      margin: 20px; }

/* !! - - -  */
/* !! ニュース */
ul.news {
  padding: 60px 100px;
  border: 1px solid #ccc;
  margin: 50px auto; }
  ul.news li {
    position: relative;
    padding-left: 248px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px; }
    ul.news li:last-of-type {
      margin-bottom: 0; }
  ul.news .date {
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0; }
  ul.news .cat {
    position: absolute;
    left: 125px;
    background: #eee;
    width: 100px;
    line-height: 30px;
    text-align: center; }
  ul.news .ttl {
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px; }
  ul.news .desc {
    font-size: 14px;
    line-height: 1.6;
    margin-top: -0.3em;
    margin-bottom: -0.3em; }

/* !! - - -  */
/* !! ページフッター */
#footer {
  margin-top: 32px !important; }
  #footer .footer_logo_area {
    margin-top: 10px !important; }

#page-footer {
  display: flex;
  justify-content: space-between;
  max-width: 1118px;
  margin: 0 auto;
  margin-top: 100px; }
  #page-footer > div {
    width: 264px;
    border-bottom: 1px dotted #ccc; }
    #page-footer > div.nb {
      border-bottom: 0 !important; }
    #page-footer > div .hd {
      background: #26B7BC;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-weight: bold;
      position: relative;
      font-size: 18px;
      letter-spacing: .1em;
      position: relative;
      margin-bottom: 15px; }
      #page-footer > div .hd:after {
        content: "";
        width: 13px;
        height: 13px;
        position: absolute;
        left: 50%;
        bottom: 0;
        background: #26B7BC;
        -webkit-transform: translate(-50%, 50%) rotate(45deg);
        -moz-transform: translate(-50%, 50%) rotate(45deg);
        -ms-transform: translate(-50%, 50%) rotate(45deg);
        -o-transform: translate(-50%, 50%) rotate(45deg);
        transform: translate(-50%, 50%) rotate(45deg); }
  #page-footer ul.fix li {
    padding: 10px 0; }
  #page-footer ul li {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
    white-space: nowrap; }
    #page-footer ul li:last-of-type {
      border-bottom: 0; }
    #page-footer ul li.bb {
      border-bottom: 1px dotted #ccc !important; }
    #page-footer ul li .red {
      color: #df002d;
      font-weight: bold; }
    #page-footer ul li.rel1 {
      box-sizing: content-box; }
    #page-footer ul li.idx {
      width: 100%; }
      #page-footer ul li.idx p {
        font-size: 12px;
        width: 84px;
        text-align: center;
        padding: 10 0px;
        border: 1px dotted #ccc; }
    #page-footer ul li a {
      display: inline-block; }
    #page-footer ul li i {
      background: #26B7BC;
      color: #fff;
      padding: 5px;
      border-radius: 4px;
      margin-right: 5px; }
    #page-footer ul li b {
      display: block;
      margin-bottom: 5px; }
    #page-footer ul li .img {
      width: 127px; }
    #page-footer ul li img {
      width: 100% !important;
      height: auto !important;
      display: block; }

.txt_bnr_area {
  display: none; }

/* !!------------------------------------ */
/* !! 下層共通 */
main.page h1.hd {
  margin: 60px 0 50px 0; }

main.page h2 {
  height: 50px;
  border: 1px solid #3e3a39;
  border-width: 1px 0;
  line-height: 50px;
  margin-bottom: 50px;
  margin-top: 100px; }
  main.page h2:first-of-type {
    margin-top: 0; }

main.page h3 {
  color: #26B7BC;
  border: 1px solid #26B7BC;
  text-align: center;
  max-width: 600px;
  margin: 50px auto;
  line-height: 50px;
  font-weight: normal; }

/* !!------------------------------------ */
/* !! ご注文コースについて */
main.page.course {
  text-align: center; }
  main.page.course .img {
    text-align: center; }
    main.page.course .img .caption {
      line-height: 1.8;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding-top: 30px;
      margin-top: -0.4em;
      margin-bottom: -0.4em; }
  main.page.course ul.merit {
    display: block;
    max-width: 880px;
    margin: 0 auto; }
    main.page.course ul.merit li {
      font-size: 32px;
      line-height: 1;
      position: relative;
      padding-left: 124px;
      line-height: 1;
      margin-top: 70px;
      white-space: nowrap;
      text-align: left; }
      main.page.course ul.merit li > img {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        main.page.course ul.merit li > img.dec-r {
          left: auto;
          right: 0; }
      main.page.course ul.merit li .txt {
        display: inline-block;
        line-height: 1.3; }
        main.page.course ul.merit li .txt strong {
          color: #e4007f; }
      main.page.course ul.merit li span {
        font-size: 0.56em;
        line-height: 1;
        display: block;
        margin-bottom: 10px; }
      main.page.course ul.merit li .dec {
        display: inline-block;
        height: 60px;
        width: auto;
        vertical-align: top;
        position: relative; }
        main.page.course ul.merit li .dec img {
          top: 50%;
          display: block;
          position: relative;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); }
    main.page.course ul.merit + .box {
      text-align: center;
      font-size: 24px;
      margin-top: 70px; }
  main.page.course .div {
    margin-top: 70px; }
  main.page.course .box {
    text-align: justify;
    padding: 50px;
    border: 1px solid #3e3a39;
    max-width: 900px;
    margin: 20px auto;
    position: relative;
    overflow: hidden; }
    main.page.course .box h4 {
      font-size: 32px;
      color: #26B7BC;
      line-height: 1;
      margin-bottom: 25px; }
    main.page.course .box p {
      line-height: 1.75; }
    main.page.course .box p.note {
      padding-left: 1.4em;
      position: relative; }
      main.page.course .box p.note i {
        font-style: normal;
        position: absolute;
        top: 0;
        left: 0; }
    main.page.course .box:after {
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      background: #26B7BC;
      z-index: 1;
      -webkit-transform: translate(50%, -50%) rotate(45deg);
      -moz-transform: translate(50%, -50%) rotate(45deg);
      -ms-transform: translate(50%, -50%) rotate(45deg);
      -o-transform: translate(50%, -50%) rotate(45deg);
      transform: translate(50%, -50%) rotate(45deg); }
  main.page.course p.note {
    padding-left: 1.4em;
    position: relative;
    max-width: 900px;
    margin: 0 auto; }
    main.page.course p.note i {
      font-style: normal;
      position: absolute;
      top: 0;
      left: 0; }
  main.page.course .wrap {
    max-width: 900px;
    padding: 50px;
    margin: 0 auto;
    margin-top: -20px;
    background: rgba(240, 240, 240, 0.57); }
    main.page.course .wrap .img {
      display: block;
      background: #fff;
      min-height: 200px;
      margin: 15px 0; }
  main.page.course .label {
    width: 200px;
    height: 30px;
    line-height: 1;
    background: #26B7BC;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px; }
  main.page.course p.label {
    margin-top: 20px;
    margin-bottom: 10px; }
  main.page.course h4 {
    margin: 15px 0;
    margin-top: 30px;
    text-align: left; }
    main.page.course h4 .label {
      display: -webkit-inline-flex;
      display: -moz-inline-flex;
      display: -ms-inline-flex;
      display: -o-inline-flex;
      display: inline-flex;
      font-size: 16px;
      margin: 0 10px; }
  main.page.course p.otamesi {
    font-size: 32px;
    text-align: center; }
    main.page.course p.otamesi strong {
      color: #e4007f !important;
      display: inline-block;
      line-height: 1;
      position: relative; }
      main.page.course p.otamesi strong:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: -10px;
        -webkit-transform: rotate(-20deg) translateY(-50%);
        -moz-transform: rotate(-20deg) translateY(-50%);
        -ms-transform: rotate(-20deg) translateY(-50%);
        -o-transform: rotate(-20deg) translateY(-50%);
        transform: rotate(-20deg) translateY(-50%);
        width: 9px;
        height: 53px;
        background-image: url(../../img/course/deco@2x.png);
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: center center; }
      main.page.course p.otamesi strong:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: -10px;
        -webkit-transform: rotate(20deg) translateY(-50%);
        -moz-transform: rotate(20deg) translateY(-50%);
        -ms-transform: rotate(20deg) translateY(-50%);
        -o-transform: rotate(20deg) translateY(-50%);
        transform: rotate(20deg) translateY(-50%);
        width: 9px;
        height: 53px;
        background-image: url(../../img/course/deco@2x.png);
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: center center; }
  main.page.course .desc {
    text-align: center;
    line-height: 1;
    margin-top: 50px; }

/* !!------------------------------------ */
/* !! 良くあるご質問 */
main.FAQ dl {
  max-width: 920px;
  margin: 0 auto; }
  main.FAQ dl dt {
    margin-bottom: 30px;
    line-height: 70px;
    padding-left: 65px;
    font-size: 18px;
    font-weight: bold;
    color: #26B7BC;
    border: 1px solid #26B7BC;
    position: relative;
    cursor: pointer; }
    main.FAQ dl dt:before {
      content: "Q.";
      line-height: 1;
      position: absolute;
      left: 30px;
      top: 50%;
      margin-top: -5px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      font-family: urw-din-semi-condensed, sans-serif;
      font-weight: bold;
      font-size: 36px; }
    main.FAQ dl dt:after {
      content: "＋";
      position: absolute;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      line-height: 1;
      font-weight: bold;
      font-size: 24px; }
    main.FAQ dl dt.opened:after {
      content: "ー" !important; }
  main.FAQ dl dd {
    padding-left: 70px;
    padding-right: 30px;
    margin: 30px 0;
    display: none;
    position: relative; }
    main.FAQ dl dd p {
      font-size: 16px;
      line-height: 1.75; }
    main.FAQ dl dd:before {
      content: "A.";
      color: #bbb;
      line-height: 1;
      position: absolute;
      left: 30px;
      top: 0;
      margin-top: -5px;
      font-family: urw-din-semi-condensed, sans-serif;
      font-weight: bold;
      font-size: 36px; }

/* !!------------------------------------ */
/* !! お支払いについて */
main.payment .table-list li {
  position: relative;
  padding: 30px;
  padding-right: 0;
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 294px;
  line-height: 1.81; }
  main.payment .table-list li .hd {
    border: 1px solid #26B7BC;
    color: #26B7BC;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 15px; }
  main.payment .table-list li p {
    line-height: 1.81; }
  main.payment .table-list li h3 {
    color: #3e3a39;
    font-weight: bold;
    letter-spacing: .1em;
    max-width: 100%;
    border: 0;
    padding: 0;
    padding-bottom: 5px !important;
    line-height: 1;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-top: 20px; }
    main.payment .table-list li h3:first-of-type {
      margin-top: 0; }
  main.payment .table-list li > span {
    display: block;
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #26B7BC;
    width: 264px;
    color: #fff;
    font-weight: bold; }

main.payment p.img {
  margin: 30px 0; }
  main.payment p.img.cresit, main.payment p.img.convenience {
    padding-left: 30px; }

/* !!------------------------------------ */
/* !!  クロワールシリーズ一覧 */
main.product .main_visual {
  display: none; }
  main.product .main_visual .item img {
    width: 100% !important;
    height: auto !important; }

main.product .mv {
  background: none;
  height: 400px;
  position: relative;
  margin-top: -30px; }
  main.product .mv p {
    width: 90% !important;
    height: 188px; }

main.product #croire-list.croire {
  width: 1118px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between; }
  main.product #croire-list.croire .cell {
    padding: 0 !important; }
    main.product #croire-list.croire .cell .inner {
      z-index: 1;
      position: relative;
      height: 100%;
      background-size: 100% auto !important;
      background-position: top right !important; }
      main.product #croire-list.croire .cell .inner .img {
        left: 30px !important; }
    main.product #croire-list.croire .cell:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #F5F4F6;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0; }
    main.product #croire-list.croire .cell:before {
      display: none;
      content: "";
      position: absolute;
      bottom: -10px;
      right: -10px;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-image: linear-gradient(135deg, #a4d7ef 25%, #7dc6e8 25%, #7dc6e8 50%, #a4d7ef 50%, #a4d7ef 75%, #7dc6e8 75%, #7dc6e8 100%);
      background-size: 14.14px 14.14px; }
  @media screen and (max-width: 767px) {
    main.product #croire-list.croire .probiotics12 .inner:after {
      content: "";
      background-size: 200% auto !important; } }
  main.product #croire-list.croire .probiotics12 .teiki .hd {
    position: relative; }
    @media screen and (min-width: 768px) {
      main.product #croire-list.croire .probiotics12 .teiki .hd {
        padding-right: 65px; } }
    @media screen and (max-width: 767px) {
      main.product #croire-list.croire .probiotics12 .teiki .hd {
        padding-left: 10.68vw;
        white-space: nowrap; } }
    main.product #croire-list.croire .probiotics12 .teiki .hd:after {
      content: "";
      display: block;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 50%;
      right: -35px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background-image: url(../../product/img/pb12_label1@2x.png);
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-position: center center; }
      @media screen and (max-width: 767px) {
        main.product #croire-list.croire .probiotics12 .teiki .hd:after {
          width: 21.36vw;
          height: 21.36vw;
          left: -15px;
          right: auto; } }
  main.product #croire-list.croire .probiotics12 .teiki .btn2 {
    display: none; }
  main.product #croire-list.croire .probiotics12 .teiki .txt {
    visibility: hidden; }
  main.product #croire-list.croire .probiotics12 .onestop .hd {
    position: relative; }
    @media screen and (min-width: 768px) {
      main.product #croire-list.croire .probiotics12 .onestop .hd {
        padding-right: 65px; } }
    @media screen and (max-width: 767px) {
      main.product #croire-list.croire .probiotics12 .onestop .hd {
        padding-left: 8.01vw; } }
    main.product #croire-list.croire .probiotics12 .onestop .hd:after {
      content: "";
      content: "";
      display: block;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 50%;
      right: -35px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      @media screen and (max-width: 767px) {
        main.product #croire-list.croire .probiotics12 .onestop .hd:after {
          width: 21.36vw;
          height: 21.36vw;
          left: -15px;
          right: auto; } }
  main.product #croire-list.croire .cell {
    order: 1;
    margin-bottom: 50px; }
    main.product #croire-list.croire .cell .ttl span {
      white-space: nowrap; }
    main.product #croire-list.croire .cell .inner {
      padding: 30px;
      padding-left: 240px !important; }
      main.product #croire-list.croire .cell .inner ul.idx {
        width: 100%;
        justify-content: space-between; }
    main.product #croire-list.croire .cell .base_price p {
      margin: 10px 0; }
    @media screen and (min-width: 768px) {
      main.product #croire-list.croire .cell.right .inner {
        margin-left: 0 !important;
        padding-right: 240px !important; }
        main.product #croire-list.croire .cell.right .inner .img {
          left: auto !important;
          right: 30px !important; }
      main.product #croire-list.croire .cell.right .ttl {
        text-align: right; }
      main.product #croire-list.croire .cell.right .base_price p {
        justify-content: flex-end; }
      main.product #croire-list.croire .cell.right ul.idx {
        margin-left: auto;
        margin-right: 0 !important; } }
    main.product #croire-list.croire .cell a {
      text-align: center; }
    main.product #croire-list.croire .cell ul {
      max-width: 640px; }
  main.product #croire-list.croire .na {
    display: block !important; }
  main.product #croire-list.croire .probiotics12:after {
    background-color: #fff;
    background-image: url(/img/croire/pb12-bg.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 100%;
    background-size: auto 100%;
    background-position: right bottom; }
  main.product #croire-list.croire .premiun-croire:after {
    background-image: url(/img/croire/cloire@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  main.product #croire-list.croire .lutein:after {
    background-image: url(/img/croire/lutein@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-position: center center; }
  main.product #croire-list.croire .pro:after {
    transform: rotateY(180deg);
    background-image: url(/img/croire/eye@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 200%;
    background-size: auto 200%;
    background-position: top right; }
  main.product #croire-list.croire .dhaepa:after {
    background-image: url(/img/croire/dha@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 50% auto;
    background-size: 50% auto;
    background-position: bottom right; }
  main.product #croire-list.croire .na:after {
    transform: rotateY(180deg);
    background-image: url(/img/croire/na@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 180%;
    background-size: auto 180%;
    background-position: bottom right; }
  main.product #croire-list.croire .q10:after {
    background-image: url(/img/croire/q10@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 150%;
    background-size: auto 150%;
    background-position: top right; }
  main.product #croire-list.croire .gold:after {
    transform: rotateY(180deg);
    background-image: url(/img/croire/gold@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 180%;
    background-size: auto 180%;
    background-position: center right; }
  main.product #croire-list.croire .wahaha:after {
    background-image: url(/img/croire/wahaha@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: auto 280%;
    background-size: auto 280%;
    background-position: top right; }
  main.product #croire-list.croire .croire-coat:after {
    content: "";
    background-image: url(../../img/croire/croiire-coat@2x.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  @media screen and (min-width: 768px) {
    main.product #croire-list.croire .croire-coat .idx {
      justify-content: flex-end !important; }
      main.product #croire-list.croire .croire-coat .idx .onestop {
        height: 180px; }
        main.product #croire-list.croire .croire-coat .idx .onestop .price, main.product #croire-list.croire .croire-coat .idx .onestop .btn2 {
          top: -20px;
          position: relative; } }
  main.product #croire-list.croire .croire-coat p._2 img {
    display: none; }

/* !! - - -  */
/* !! ポップアップ */
#shade {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 10;
  width: 110vw;
  height: 140%;
  opacity: 0;
  pointer-events: none;
  transition: 300ms;
  background: rgba(0, 0, 0, 0.3); }
  body.popup #shade {
    opacity: 1;
    pointer-events: auto; }

#popup {
  transition: 1000ms cubic-bezier(0.79, 0, 0.29, 0.99);
  transition-delay: 300ms;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 30px);
  -moz-transform: translate(-50%, 30px);
  -ms-transform: translate(-50%, 30px);
  -o-transform: translate(-50%, 30px);
  transform: translate(-50%, 30px);
  max-width: 800px;
  width: 800px;
  padding: 50px 50px;
  background: #fff;
  z-index: 11; }
  body.popup #popup {
    opacity: 1;
    pointer-events: auto; }
    @media screen and (min-width: 768px) {
      body.popup #popup {
        -webkit-transform: translate(-50%, 20px);
        -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
        -o-transform: translate(-50%, 20px);
        transform: translate(-50%, 20px); }
        body.popup #popup.onestop {
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); } }
  #popup.na {
    display: block !important; }
  @media screen and (max-width: 767px) {
    #popup {
      position: fixed; } }
  @media screen and (min-width: 768px) {
    #popup.onestop {
      position: fixed;
      -webkit-transform: translate(-50%, -48%);
      -moz-transform: translate(-50%, -48%);
      -ms-transform: translate(-50%, -48%);
      -o-transform: translate(-50%, -48%);
      transform: translate(-50%, -48%); } }
  #popup .close {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
    background-image: url(../img/close@2x.png);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  #popup h2 {
    color: #26B7BC;
    font-size: 22px;
    letter-spacing: .1em;
    line-height: 1; }
    #popup h2:after {
      content: "";
      display: block;
      height: 7px;
      margin: 15px 0;
      background-image: url(../img/bg-strp@2x.png);
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-position: center center; }
  #popup ul.idx {
    font-size: 16px; }
    #popup ul.idx li {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 150px;
      height: 100px;
      border: 1px solid #26B7BC;
      border-radius: 8px;
      line-height: 1.6;
      color: #26B7BC; }
  #popup h3 {
    line-height: 1;
    font-size: 16px;
    letter-spacing: .1em;
    padding-bottom: 10px;
    border-bottom: 1px solid #26B7BC;
    margin-top: 20px; }
    #popup h3 i {
      color: #26B7BC;
      padding-right: 4px; }
    #popup h3 .pb12 {
      display: none;
      font-size: 12px; }
      .test #popup h3 .pb12 {
        display: inline; }
      @media screen and (max-width: 767px) {
        .test #popup h3 .pb12 {
          display: block; } }
  #popup p {
    letter-spacing: 0;
    margin-top: 10px;
    font-size: 15px; }
  #popup p.pb12 {
    display: none; }
    .test #popup p.pb12 {
      display: block; }
  #popup #teiki-link-btn {
    margin: 0 auto;
    margin-top: 40px;
    width: 400px;
    height: 58px;
    background: #26B7BC;
    font-size: 18px;
    letter-spacing: .1em;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transition: 100ms;
    box-shadow: 0 2px 0 #19797c; }
    #popup #teiki-link-btn:hover {
      box-shadow: 0 1px 0 #19797c;
      top: 1px; }
    #popup #teiki-link-btn + .note {
      font-size: 13px !important;
      margin-top: 25px !important; }
  #popup .teiki, #popup .onestop {
    display: none; }
    #popup .teiki.on, #popup .onestop.on {
      display: block; }
  #popup.probiotics12.onestop ._1 .hd {
    position: relative; }
    #popup.probiotics12.onestop ._1 .hd:after {
      content: "";
      display: block;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 50%;
      right: -5px;
      -webkit-transform: translate(0%, -50%);
      -moz-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      -o-transform: translate(0%, -50%);
      transform: translate(0%, -50%); }
      @media screen and (max-width: 767px) {
        #popup.probiotics12.onestop ._1 .hd:after {
          width: 21.36vw;
          height: 21.36vw;
          left: -10px;
          right: auto; } }
  #popup .onestop > div {
    border-bottom: 1px solid #9FA0A0;
    margin-bottom: 20px;
    padding-bottom: 20px; }
    #popup .onestop > div p {
      color: #B81C22;
      font-weight: bold;
      letter-spacing: .1em;
      font-size: 18px;
      font-weight: bold;
      color: #B81C22;
      vertical-align: top;
      position: relative;
      top: -3px; }
      #popup .onestop > div p img {
        display: block;
        width: 109px !important;
        margin-right: 10px; }
      #popup .onestop > div p i {
        font-family: urw-din-semi-condensed, sans-serif;
        font-size: 40px; }
      #popup .onestop > div p.price {
        height: 36px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start; }
        #popup .onestop > div p.price span {
          display: -webkit-inline-flex;
          display: -moz-inline-flex;
          display: -ms-inline-flex;
          display: -o-inline-flex;
          display: inline-flex;
          align-items: center;
          height: 36px; }
        #popup .onestop > div p.price .price_1 {
          align-self: flex-start !important; }
        #popup .onestop > div p.price .price_2, #popup .onestop > div p.price .price_3 {
          position: relative; }
          @media screen and (min-width: 768px) {
            #popup .onestop > div p.price .price_2 b, #popup .onestop > div p.price .price_3 b {
              position: absolute;
              left: -10px;
              top: 0;
              height: 36px;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              -webkit-transform: translateX(-100%);
              -moz-transform: translateX(-100%);
              -ms-transform: translateX(-100%);
              -o-transform: translateX(-100%);
              transform: translateX(-100%); } }
        #popup .onestop > div p.price .btn2 {
          margin: 0 !important;
          margin-left: auto !important;
          margin-right: 0 !important; }
    #popup .onestop > div:last-of-type {
      margin-bottom: 0; }
    #popup .onestop > div._1 {
      border-bottom: 1px solid #EE869A; }
      #popup .onestop > div._1 .hd {
        background: #EE869A; }
    #popup .onestop > div._2 img {
      opacity: 0; }
  #popup .onestop .hd {
    height: 30px;
    margin-bottom: 20px;
    background: #9FA0A0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    letter-spacing: .1em;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      #popup .onestop .hd {
        display: block;
        height: auto !important;
        padding: 5px 10px;
        margin-left: 0 !important; }
        #popup .onestop .hd .note {
          font-size: 12px; } }
    @media screen and (min-width: 768px) {
      #popup .onestop .hd {
        display: -webkit-flex !important;
        display: -moz-flex !important;
        display: -ms-flex !important;
        display: -o-flex !important;
        display: flex !important;
        flex-direction: row;
        align-items: center !important;
        justify-content: center; }
        #popup .onestop .hd .note {
          display: inline-block !important;
          font-size: 13px; } }

/* !!------------------------------------ */
/* !! ログイン画面 */
.col-sm-8.col-sm-offset-2 {
  padding: 30px !important;
  padding-top: 0 !important;
  text-align: center !important; }

/* !!------------------------------------ */
/* !! 商品一覧 */
#result_list_box--129,
#result_list_box--16,
#result_list_box--135,
#result_list_box--137,
#result_list_box--138,
#result_list_box--139,
#result_list_box--140,
#result_list_box--141,
#result_list_box--142,
#result_list_box--143,
#result_list_box--145,
#result_list_box--146,
#result_list_box--144,
#result_list_box--148 {
  display: none !important; }

/* !!------------------------------------ */
/* !! 商品詳細画面 */
.PB12_1 #detail_description_box__name {
  position: relative; }
  .PB12_1 #detail_description_box__name:after {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    right: -15px;
    background-image: url(/product/img/pb12_label1@2x.png);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
    @media screen and (max-width: 767px) {
      .PB12_1 #detail_description_box__name:after {
        width: 21.36vw;
        height: 21.36vw;
        top: -30px;
        right: -15px; } }

.PB12_1 #detail_description_box__sale_price {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .PB12_1 #detail_description_box__sale_price {
      padding: 10px 0; } }
  .PB12_1 #detail_description_box__sale_price .price02_default {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .PB12_1 #detail_description_box__sale_price .price02_default {
        margin-right: 5px;
        margin-top: 15px; } }
    .PB12_1 #detail_description_box__sale_price .price02_default:before {
      content: "初回・2回目以降\A 30%OFF";
      white-space: pre;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      display: -webkit-inline-flex;
      display: -moz-inline-flex;
      display: -ms-inline-flex;
      display: -o-inline-flex;
      display: inline-flex;
      font-size: 12px;
      border: 2px solid #EA5532;
      text-align: center;
      padding: 0 10px;
      height: 40px;
      line-height: 1.2;
      margin-right: 10px; }

.PB12_1 #detail_cart_box:after {
  content: "※本品とその他クロワール商品を同梱してお届けする場合、\A佐川急便での配送となります。";
  white-space: pre; }
  @media screen and (max-width: 767px) {
    .PB12_1 #detail_cart_box:after {
      font-size: 13px;
      white-space: normal; } }

.PB12_2 #detail_description_box__name {
  position: relative; }
  .PB12_2 #detail_description_box__name:after {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    right: -15px; }
    @media screen and (max-width: 767px) {
      .PB12_2 #detail_description_box__name:after {
        width: 21.36vw;
        height: 21.36vw;
        top: -30px;
        right: -15px; } }

/* !! iframe */
iframe.detail {
  display: block;
  width: 100%; }

span.type {
  display: inline-block;
  border: 2px solid #00B9EE;
  color: #00B9EE;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .1em;
  padding: 5px 10px;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    span.type {
      font-size: 14px; } }

#site-header {
  display: none !important; }

#detail_description_box__sale_point {
  display: none !important; }

#summary_box__customer_point + #summary_box__customer_point {
  display: none !important; }

/* !!------------------------------------ */
/* !! フッター */
#footer #page-footer {
  max-width: 1118px;
  margin: 0 auto;
  margin-bottom: 0px; }

.footer_logo_area p.logo {
  text-align: center; }

.footer_logo_area a {
  display: block; }
  .footer_logo_area a img {
    display: inline-block;
    width: 200px;
    height: auto;
    vertical-align: middle; }
  .footer_logo_area a span {
    vertical-align: middle;
    font-size: 1.2rem;
    margin-left: 10px; }

.footer_logo_area .copyright {
  text-align: center !important; }

/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! 商品詳細 */
.price02_default.teiki {
  font-size: 2.8rem;
  display: block; }
  .price02_default.teiki:before {
    content: "初回50%OFF ";
    font-size: 2.2rem; }
  .price02_default.teiki:after {
    content: "円(税抜)";
    font-size: 2.2rem; }

.price02_default-s {
  display: block;
  font-size: 2.2rem !important; }

/* !!------------------------------------ */
/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! ★商品単体ページ共通 */
#item_photo_area .slick-slide img {
  max-height: 464px;
  width: auto !important; }

ul.path.product {
  top: 0;
  position: relative;
  z-index: 2;
  height: 50px; }

main.single {
  margin-top: 0px;
  position: relative;
  z-index: 1; }
  main.single > section {
    padding: 0; }
  main.single h2 {
    padding: 10px 0;
    font-size: 24px;
    height: auto;
    padding-bottom: 10px;
    position: relative; }
  main.single .mv {
    height: 400px;
    overflow: hidden;
    -webkit-background-size: cover;
    background-size: cover !important; }
    main.single .mv .inner {
      position: relative;
      height: auto;
      max-width: 1200px;
      height: 260px;
      margin: 70px auto !important; }
      main.single .mv .inner .img {
        float: right;
        margin-right: 50px; }
    main.single .mv .data {
      height: 260px;
      float: right;
      margin-right: 50px;
      position: relative; }
    main.single .mv h1 {
      text-align: right;
      font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
      font-size: 2.4rem;
      font-weight: normal;
      margin-top: -0.355em;
      margin-bottom: -0.355em;
      line-height: 1.71;
      letter-spacing: .1em;
      position: relative;
      top: 82px; }
    main.single .mv .logo {
      position: absolute;
      bottom: 0;
      right: 0; }
  main.single .lead {
    padding: 100px 0;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 100px 0; }
    main.single .lead h2 {
      line-height: 1.7;
      font-size: 24px;
      padding-bottom: 10px; }
    main.single .lead p {
      text-align: center !important;
      margin-top: -0.4em;
      margin-bottom: -0.4em; }
  main.single section.about {
    overflow: hidden;
    background: #EAF6FD; }
    main.single section.about .inner {
      padding: 0;
      max-width: 1000px;
      margin: 100px auto !important; }
      main.single section.about .inner h2.Q {
        line-height: 1.7;
        text-align: center;
        border-bottom: 1px solid #BDBAB9;
        padding-bottom: 10px;
        margin-bottom: 0 !important; }
      main.single section.about .inner p.A {
        padding-top: 20px;
        text-align: justify;
        margin-top: -0.375em;
        margin-bottom: -0.375em; }
  main.single h2.bd {
    line-height: 1.7;
    text-align: center !important;
    height: auto;
    border-bottom: 1px solid #BDBAB9;
    padding: 10px 0;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    margin-top: 50px !important; }
  main.single > section .inner {
    max-width: 1000px;
    margin: 0 auto;
    padding: 100px 0; }
    main.single > section .inner h2 {
      line-height: 1.7;
      text-align: center;
      margin-top: -0.375em;
      margin-bottom: -0.375em;
      margin: 100px 0 50px 0; }
      main.single > section .inner h2:first-of-type {
        margin-top: 0; }
  main.single h3.border {
    max-width: 383px;
    line-height: 38px;
    border: 1px solid #3e3a39;
    text-align: center;
    border-radius: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin: 50px auto; }
  main.single ul.img-list {
    font-size: 0;
    text-align: center; }
    main.single ul.img-list._50 li {
      margin: 0 25px; }
    main.single ul.img-list li {
      width: 150px;
      margin: 0 15px;
      margin-bottom: 30px;
      display: inline-block;
      vertical-align: top; }
      main.single ul.img-list li .img {
        width: 100%;
        height: 150px;
        overflow: hidden;
        background: #eee;
        border-radius: 50%; }
        main.single ul.img-list li .img img {
          width: 100% !important;
          height: auto !important;
          display: block; }
      main.single ul.img-list li span {
        text-align: center;
        display: block;
        margin-top: 10px;
        font-size: 1.6rem;
        width: 200%;
        left: 50%;
        letter-spacing: .05em;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        position: relative; }
        main.single ul.img-list li span b {
          display: block;
          font-size: 1.13em; }
          main.single ul.img-list li span b i {
            font-size: 1.22em; }
    main.single ul.img-list.cen span {
      text-align: center !important; }
    main.single ul.img-list.mid li {
      width: 195px;
      vertical-align: top; }
      main.single ul.img-list.mid li .img {
        height: auto; }
      main.single ul.img-list.mid li span {
        width: 100% !important;
        left: 0;
        -webkit-transform: translate(0);
        -moz-transform: translate(0);
        -ms-transform: translate(0);
        -o-transform: translate(0);
        transform: translate(0);
        text-align: justify; }
    main.single ul.img-list.lg li {
      width: 250px;
      vertical-align: top; }
      main.single ul.img-list.lg li:nth-last-child(-n+3) {
        margin-bottom: 0; }
      main.single ul.img-list.lg li .img {
        height: auto; }
      main.single ul.img-list.lg li span {
        padding-top: 20px;
        margin-top: 20px;
        margin-top: -0.35em;
        margin-bottom: -0.35em; }
  main.single ul.img-list2 {
    display: flex;
    justify-content: space-between; }
    main.single ul.img-list2 li {
      width: calc((100% - 90px)/4); }
      main.single ul.img-list2 li .img {
        background: #fff;
        height: 200px;
        position: relative; }
        main.single ul.img-list2 li .img img {
          width: 100% !important;
          height: auto !important; }
        main.single ul.img-list2 li .img i {
          position: absolute;
          bottom: 0;
          left: 0;
          line-height: 22px;
          background: #FACD00;
          width: 110px;
          text-align: center;
          font-size: 1.6rem;
          z-index: 1; }
      main.single ul.img-list2 li p {
        font-size: 1.6rem; }
        main.single ul.img-list2 li p b {
          display: block;
          font-size: 1.13em;
          line-height: 1;
          margin: 20px 0 10px 0; }
  main.single section.bg-GR {
    background: #EFEFEF;
    overflow: hidden; }
    main.single section.bg-GR .inner {
      max-width: 1000px;
      margin: 0 auto; }
  main.single .note {
    font-size: 1.4rem; }
  main.single table.nutrient {
    table-layout: fixed;
    width: 100% !important;
    height: auto !important;
    border-collapse: collapse;
    position: relative; }
    main.single table.nutrient caption {
      font-size: 1.6rem;
      letter-spacing: .1em;
      position: absolute;
      top: -2em;
      right: 0; }
    main.single table.nutrient th, main.single table.nutrient td {
      border: 1px solid #BDBAB9;
      padding: 20px;
      font-size: 1.6rem !important; }
    main.single table.nutrient th {
      background: #EAF6FD;
      width: 200px; }
    main.single table.nutrient td {
      font-size: 1.4rem;
      text-align: right; }
    main.single table.nutrient + table.material {
      margin-top: 50px; }
    main.single table.nutrient + p.ar {
      font-size: 1.6rem;
      margin-top: 10px; }
  main.single table.material {
    margin-top: 100px;
    width: 100%;
    border-collapse: collapse; }
    main.single table.material th, main.single table.material td {
      border: 1px solid #BDBAB9;
      padding: 20px; }
    main.single table.material th {
      background: #EAF6FD;
      font-size: 2rem;
      width: 200px; }
    main.single table.material td {
      font-size: 1.4rem; }
  main.single p.hd {
    line-height: 50px;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .1em;
    border-radius: 25px; }
    main.single p.hd.box {
      border-radius: 0; }
    main.single p.hd.border {
      border: 1px solid #3e3a39; }
  main.single section .inner > div.box {
    padding: 50px 0;
    border-bottom: 1px dotted #333; }
  main.single div.img-left {
    position: relative; }
    main.single div.img-left .img {
      width: 250px;
      height: 250px;
      margin-left: 50px; }
      main.single div.img-left .img img {
        width: 100% !important;
        height: auto !important;
        display: block; }
    main.single div.img-left.block .img {
      width: 50%;
      height: auto;
      margin-left: 0; }
      main.single div.img-left.block .img .caption {
        font-size: 1.8em;
        font-weight: bold;
        line-height: 1;
        line-height: 30px; }
    main.single div.img-left.block .txt {
      left: 50%;
      width: 50%;
      padding-left: 50px;
      margin-top: 15px; }
    main.single div.img-left .txt {
      position: absolute;
      text-align: left;
      left: 350px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      main.single div.img-left .txt h2 {
        text-align: left;
        margin: 0;
        line-height: 1;
        margin-bottom: 20px; }
      main.single div.img-left .txt p {
        text-align: left;
        margin-top: -0.35em;
        margin-bottom: -0.35em; }
  main.single div.img-right {
    position: relative; }
    main.single div.img-right .img {
      padding-left: 50%;
      text-align: center; }
    main.single div.img-right .txt {
      position: absolute;
      text-align: left;
      left: 0;
      width: 50%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      main.single div.img-right .txt h3 {
        text-align: left;
        margin: 0;
        line-height: 1;
        font-size: 2.4rem;
        letter-spacing: .1em;
        font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
        font-weight: normal;
        margin-bottom: 50px;
        border: none !important; }
      main.single div.img-right .txt p {
        text-align: left;
        margin-top: -0.35em;
        margin-bottom: -0.35em; }

#relative_category_box {
  display: none !important; }

#detail_cart_box__cart_class_category_id[data-type=定期購入] {
  opacity: 0;
  height: 0; }

/* !!------------------------------------ */
/* !! ★プレミアムクロワール茶 */
main.premium-croire .mv {
  background-image: url(../../product/img/bg01.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

main.premium-croire .img.sod {
  text-align: center; }

main.premium-croire .desc {
  text-align: center;
  margin-top: 50px; }

main.premium-croire .result {
  position: relative;
  padding-right: 335px; }
  main.premium-croire .result .img {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #BDBAB9; }
  main.premium-croire .result .note {
    margin-top: 10px; }
  main.premium-croire .result h3 {
    color: #fff;
    line-height: 40px;
    background: #B81C22;
    font-size: 2rem;
    padding-left: 15px;
    margin: 50px 0 30px 0; }
  main.premium-croire .result .red {
    color: #B81C22;
    font-size: 2rem;
    position: relative;
    padding-right: 240px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px; }
    main.premium-croire .result .red img {
      height: 50px;
      width: auto;
      position: absolute;
      top: 0;
      right: 0; }

main.premium-croire .idx {
  display: flex;
  justify-content: space-between; }

/* !!------------------------------------ */
/* !! ★クロワールアイ・プロ */
main.croire-eye-pro .mv {
  background-image: url(../../product/img/bg02.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

main.croire-eye-pro .idx {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  main.croire-eye-pro .idx.rev {
    flex-direction: row-reverse; }

main.croire-eye-pro .graf {
  width: 900px;
  margin: 0 auto;
  margin-top: 50px; }
  main.croire-eye-pro .graf .txt {
    width: 410px;
    height: 238px; }
    main.croire-eye-pro .graf .txt p {
      position: relative;
      top: 50%;
      margin-top: -20px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }

main.croire-eye-pro .img.ac {
  text-align: center; }

main.croire-eye-pro p.ac {
  text-align: center; }

main.croire-eye-pro h2.bd + p {
  padding-top: 30px;
  margin-top: -0.35em;
  margin-bottom: -0.35em; }

main.croire-eye-pro img.KEMIN {
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 20px; }

main.croire-eye-pro .mt30 {
  margin-top: 30px; }

main.croire-eye-pro .idx.box dl {
  max-width: 475px;
  margin: 0; }
  main.croire-eye-pro .idx.box dl dt {
    position: relative;
    text-align: center;
    padding-top: 20px; }
    main.croire-eye-pro .idx.box dl dt span {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold;
      font-size: 2.2rem; }
  main.croire-eye-pro .idx.box dl dd {
    font-size: 1.6rem;
    padding-top: 20px;
    margin-top: -0.35em;
    margin-bottom: -0.35em; }

main.croire-eye-pro ul.img-list._200 {
  width: 800px;
  margin: 0 auto; }
  main.croire-eye-pro ul.img-list._200 li {
    width: 200px !important;
    margin: 0 25px;
    margin-bottom: 30px; }
    main.croire-eye-pro ul.img-list._200 li:nth-last-child(-n+3) {
      margin-bottom: 0; }
    main.croire-eye-pro ul.img-list._200 li b {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      display: block;
      margin-bottom: 20px; }
    main.croire-eye-pro ul.img-list._200 li .img {
      height: auto; }

main.croire-eye-pro ul.more-elem {
  font-size: 0;
  text-align: center; }
  main.croire-eye-pro ul.more-elem li {
    display: inline-block;
    letter-spacing: .1em;
    margin: 0 10px;
    line-height: 60px;
    padding: 0 20px;
    min-width: 133px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold; }

/* !!------------------------------------ */
/* !! ★クロワールアイゴールド */
main.croire-gold .mv {
  background-image: url(../../product/img/bg06.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

/* !!------------------------------------ */
/* !! ★クロワールアイ・ルテイン+ゼアキサンチンゼリー */
main.croire-eye-lutein .mv {
  background-image: url(../../product/img/bg02.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

main.croire-eye-lutein ul.img-list.mid.cen li {
  width: 213px; }
  main.croire-eye-lutein ul.img-list.mid.cen li span {
    white-space: nowrap; }

/* !!------------------------------------ */
/* !! ★還元型コエンザイムQ10 */
main.Q10 .mv {
  background-image: url(../../product/Q10/img/mv-bg.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

main.Q10 div.img-right.block {
  margin-top: 100px; }
  main.Q10 div.img-right.block .txt {
    padding-right: 50px; }
  main.Q10 div.img-right.block h3 {
    line-height: 1.75;
    margin-top: -0.375em;
    margin-bottom: -0.375em;
    padding-bottom: 50px;
    border: none !important; }
  main.Q10 div.img-right.block h4 {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 20px; }

main.Q10 div.img-left.block .txt {
  margin-top: 0; }

main.Q10 .img-list li {
  margin-bottom: 0 !important; }

main.Q10 p.ac {
  text-align: center; }

main.Q10 ul.img-list.food {
  max-width: 600px;
  margin: 0 auto; }
  main.Q10 ul.img-list.food li {
    margin: 0 25px 50px 25px !important; }
    main.Q10 ul.img-list.food li:nth-last-of-type(-n+3) {
      margin-bottom: 0 !important; }

main.Q10 p.hd {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 20px; }
  main.Q10 p.hd strong {
    font-size: 1.2em;
    font-weight: 600;
    display: block;
    margin-bottom: 20px; }

main.Q10 ul.idx.vitamin {
  margin: 0 auto;
  text-align: center;
  margin-bottom: -25px; }
  main.Q10 ul.idx.vitamin li {
    max-width: 415px;
    display: inline-block;
    width: 100%;
    height: 200px;
    position: relative;
    margin: 25px;
    border: 1px solid #fff;
    border-radius: 10px; }
    main.Q10 ul.idx.vitamin li p {
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.33;
      position: relative;
      color: #fff;
      top: 50%;
      margin-top: -0.165em;
      margin-bottom: -0.165em;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      main.Q10 ul.idx.vitamin li p span {
        display: block;
        font-size: 0.5em; }

main.Q10 .sec.gyoushuku {
  margin: 100px 0; }
  @media screen and (max-width: 767px) {
    main.Q10 .sec.gyoushuku {
      margin: 13.35vw 0; } }
  main.Q10 .sec.gyoushuku h2 {
    border: none;
    padding: 0; }
  main.Q10 .sec.gyoushuku h3 {
    color: #3e3a39;
    margin: 0;
    font-size: 24px;
    border: 0;
    border-bottom: 1px solid #3e3a39;
    padding-bottom: 10px;
    text-align: left;
    padding: 0;
    letter-spacing: .1em;
    font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
    max-width: 1000px;
    margin-bottom: 10px; }
    main.Q10 .sec.gyoushuku h3 img {
      display: block; }
    @media screen and (max-width: 767px) {
      main.Q10 .sec.gyoushuku h3 {
        font-size: 18px; }
        main.Q10 .sec.gyoushuku h3 img {
          width: 50px; } }
  main.Q10 .sec.gyoushuku p {
    font-size: 18px; }
    main.Q10 .sec.gyoushuku p strong {
      font-size: 22px;
      color: #C30D23;
      font-weight: 600; }
    @media screen and (max-width: 767px) {
      main.Q10 .sec.gyoushuku p {
        font-size: 16px; }
        main.Q10 .sec.gyoushuku p strong {
          font-size: 18px; } }
  main.Q10 .sec.gyoushuku dl {
    font-size: 18px;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px; }
    main.Q10 .sec.gyoushuku dl dt {
      width: 290px;
      height: 56px;
      border: 1px solid #C30D23;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      font-weight: bold;
      color: #C30D23; }
    main.Q10 .sec.gyoushuku dl dd {
      margin-top: 10px;
      width: 680px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: justify; }
    @media screen and (max-width: 767px) {
      main.Q10 .sec.gyoushuku dl {
        margin-top: 10px;
        font-size: 4.005vw; }
        main.Q10 .sec.gyoushuku dl dt {
          width: 120px;
          height: auto;
          text-align: center; }
        main.Q10 .sec.gyoushuku dl dd {
          width: calc(100% - 130px); } }

/* !!------------------------------------ */
/* !! 決済画面 */
h2.heading02 {
  margin-bottom: 15px; }

#confirm_box__quantity_edit_button {
  margin-top: 10px; }

#cart_item__point_info {
  display: none; }

select[id$=_shippingDeliveryDate] span {
  display: none; }

body.type_teiki #cart_item__info.message {
  display: none; }

#page_epsilon_shopping_payment #main {
  padding: 8px !important; }

#page_epsilon_shopping_payment #payment_form_body {
  margin-bottom: 15px; }
  #page_epsilon_shopping_payment #payment_form_body dt {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px; }
  #page_epsilon_shopping_payment #payment_form_body .radio {
    margin: 10px 0; }
  @media screen and (max-width: 767px) {
    #page_epsilon_shopping_payment #payment_form_body input[id^=convenience_kana] {
      display: block;
      width: 100% !important;
      margin: 5px 0 10px 0; } }
  #page_epsilon_shopping_payment #payment_form_body input[id^=convenience_tel] {
    width: 70px; }

.btn_group {
  margin-top: 15px; }
  .btn_group p {
    margin: 10px; }

/* !!------------------------------------ */
/* !! 最近チェックした商品 */
.item_gallery {
  display: none; }

/* !!------------------------------------ */
/* !! マイページ */
/* !! - - -  */
/* !! ご注文履歴 */
#summary_box__add_point {
  display: none !important; }

.pc {
  display: block; }

.sp {
  display: none !important; }

#responsive_flg {
  display: none; }

@media screen and (max-width: 767px) {
  .sp {
    display: block !important; }
  .pc {
    display: none !important; }
  html {
    font-size: 50%; }
  .col-sm-12 {
    padding: 0; }
  main {
    padding: 5.34vw; }
  main > section {
    padding-top: 50px; }
  p {
    font-size: 14px; }
  p.note {
    font-size: 12px; }
  main .inner {
    max-width: 100% !important;
    width: 100%; }
  .col-sm-12 {
    padding: 0 !important; }
  /* !!------------------------------------ */
  /* !! ヘッダー */
  .header_logo_area .copy {
    font-size: 1rem !important;
    text-align: left; }
    .header_logo_area .copy br {
      display: none !important; }
  .header_logo_area i {
    display: inline-block;
    white-space: nowrap; }
  .header_logo_area img {
    width: 40.05vw !important;
    height: auto;
    display: inline-block;
    vertical-align: middle; }
  .header_logo_area h1 {
    white-space: nowrap; }
    .header_logo_area h1 a {
      display: block;
      white-space: nowrap; }
  .header_logo_area span {
    display: inline-block;
    font-size: 1.2rem;
    vertical-align: middle;
    margin-left: 10px; }
  #category_id {
    appearance: none !important;
    display: none !important; }
  #category {
    display: none !important; }
  /* !!------------------------------------ */
  /* !! トップページ */
  .slick-slide img {
    width: 100% !important;
    height: auto !important; }
  h1.hd,
  h2 {
    font-size: 14px;
    font-family: "Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
    text-align: center; }
    h1.hd i,
    h2 i {
      font-size: 32px;
      font-family: urw-din-semi-condensed, sans-serif;
      font-weight: 300; }
  /* !! - - -  */
  /* !! ランキング */
  ul.rank {
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    width: auto;
    max-width: 950px;
    margin: 0 auto;
    margin-top: 50px;
    margin: 0px; }
    ul.rank li {
      width: calc(50% - 5px);
      margin-bottom: 30px; }
      ul.rank li .number {
        font-family: urw-din-semi-condensed, sans-serif;
        font-weight: 600;
        text-align: center;
        font-size: 36px;
        display: block;
        line-height: 1;
        margin-bottom: 15px; }
      ul.rank li .img {
        height: auto;
        background: #eee;
        position: relative;
        overflow: hidden;
        border: 1px solid #ddd; }
        ul.rank li .img img {
          width: 100% !important;
          height: auto !important; }
        ul.rank li .img:after {
          content: "";
          width: 28px;
          height: 28px;
          position: absolute;
          bottom: -14px;
          right: -14px;
          z-index: 1;
          background: #000;
          transform: rotate(45deg); }
      ul.rank li .ttl {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 0; }
      ul.rank li .price {
        font-size: 14px;
        text-align: left;
        position: relative;
        height: auto;
        line-height: 1;
        margin-bottom: 10px; }
        ul.rank li .price.teiki {
          color: #AF1E24; }
          ul.rank li .price.teiki b {
            display: inline-block;
            color: #fff;
            background: #AF1E24;
            line-height: 20px;
            padding: 0 5px;
            margin-right: 2px;
            vertical-align: top;
            font-size: 13px; }
          ul.rank li .price.teiki i {
            color: #AF1E24; }
        ul.rank li .price span {
          position: relative;
          display: block;
          left: 0;
          top: 0;
          line-height: 1;
          font-size: 13px;
          color: #3e3a39;
          margin-bottom: 5px;
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0); }
        ul.rank li .price i {
          font-size: 1.36em;
          font-family: urw-din-semi-condensed, sans-serif;
          font-weight: 500; }
      ul.rank li .detail {
        display: block;
        margin-top: 15px;
        border: 1px solid #26B7BC;
        text-align: center;
        color: #26B7BC;
        line-height: 28px;
        position: relative;
        font-weight: bold; }
        ul.rank li .detail:after {
          display: block;
          position: absolute;
          top: 50%;
          left: auto;
          right: 10px;
          content: "";
          width: 6px;
          height: 6px;
          border: 1px solid #26B7BC;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(-45deg) translateY(-50%);
          -moz-transform: rotate(-45deg) translateY(-50%);
          -ms-transform: rotate(-45deg) translateY(-50%);
          -o-transform: rotate(-45deg) translateY(-50%);
          transform: rotate(-45deg) translateY(-50%);
          transform-origin: center center;
          left: auto;
          right: 10px; }
      ul.rank li:nth-of-type(1) .number {
        color: #947F28; }
      ul.rank li:nth-of-type(1) .img:after {
        background: #947F28; }
      ul.rank li:nth-of-type(2) .number {
        color: #727171; }
      ul.rank li:nth-of-type(2) .img:after {
        background: #727171; }
      ul.rank li:nth-of-type(3) .number {
        color: #955E29; }
      ul.rank li:nth-of-type(3) .img:after {
        background: #955E29; }
      ul.rank li:nth-of-type(4) .number {
        color: #8AA3D4; }
      ul.rank li:nth-of-type(4) .img:after {
        background: #8AA3D4; }
  /* !! - - -  */
  /* !! クロワール */
  section#product {
    margin-left: -5.34vw !important;
    width: 100vw !important; }
    section#product .slide {
      text-align: center;
      margin: 0;
      margin-top: 30px;
      margin-bottom: 20px; }
      section#product .slide .slick-prev, section#product .slide .slick-next {
        display: none !important; }
      section#product .slide .cell {
        position: relative;
        padding: 0 5.34vw;
        width: 100%;
        height: auto;
        display: inline-block;
        position: relative; }
        section#product .slide .cell .inner {
          position: relative;
          padding-left: 0;
          top: 0%;
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0);
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap;
          justify-content: space-between; }
          section#product .slide .cell .inner .img {
            width: 32.04vw;
            height: auto;
            position: relative;
            left: 0;
            top: 0;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0); }
          section#product .slide .cell .inner .data {
            width: calc(100% - 37.38vw); }
        section#product .slide .cell .ttl {
          font-size: 18px;
          padding-left: 0vw;
          font-weight: bold;
          text-align: left;
          line-height: 1.5;
          margin-bottom: 10px; }
          section#product .slide .cell .ttl span {
            font-size: 12px;
            margin: 0;
            font-weight: normal;
            display: block; }
        section#product .slide .cell ul {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          -o-flex-wrap: wrap;
          flex-wrap: wrap;
          justify-content: space-between; }
          section#product .slide .cell ul > li {
            width: 100%;
            margin-top: 15px; }
            section#product .slide .cell ul > li .txt {
              text-align: center; }
            section#product .slide .cell ul > li img {
              width: auto !important; }
            section#product .slide .cell ul > li.onestop .txt {
              display: none; }
            section#product .slide .cell ul > li.onestop .price {
              margin: 20px 0;
              margin-bottom: 0; }
  /* !! - - -  */
  /* !! クロ割 */
  #crowari {
    margin-left: -5.34vw !important;
    width: 100vw !important; }
    #crowari h2 img {
      width: 100% !important;
      height: auto !important; }
    #crowari ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      max-width: 890px;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px 10px; }
      #crowari ul li {
        width: calc(50% - 5px);
        margin-bottom: 10px; }
        #crowari ul li img {
          width: 100% !important;
          height: auto !important; }
  .btn-more {
    margin: 0 auto;
    display: block;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid #26B7BC;
    text-align: center;
    color: #26B7BC;
    font-weight: bold;
    letter-spacing: .1em;
    font-size: 16px;
    line-height: 46px;
    position: relative; }
    .btn-more:after {
      display: block;
      position: absolute;
      top: 50%;
      left: auto;
      right: 10px;
      content: "";
      width: 8px;
      height: 8px;
      border: 1px solid #26B7BC;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(-45deg) translateY(-50%);
      -moz-transform: rotate(-45deg) translateY(-50%);
      -ms-transform: rotate(-45deg) translateY(-50%);
      -o-transform: rotate(-45deg) translateY(-50%);
      transform: rotate(-45deg) translateY(-50%);
      transform-origin: center center;
      left: auto;
      right: 20px; }
  /* !! - - -  */
  /* !! マガジン */
  ul.magazine {
    max-width: 960px;
    width: 100%;
    margin: 50px auto;
    position: relative;
    margin-bottom: 100px;
    display: block;
    background: #F5F3F2;
    width: 100vw;
    margin-left: -5.34vw;
    padding: 5.34vw; }
    ul.magazine:after {
      display: none; }
    ul.magazine li {
      width: 100%;
      position: relative;
      z-index: 1;
      background: #fff;
      padding-bottom: 30px;
      margin-bottom: 10px; }
      ul.magazine li:last-of-type {
        margin-bottom: 0; }
      ul.magazine li .img {
        height: auto;
        background: #eee; }
        ul.magazine li .img img {
          width: 100% !important;
          height: auto !important; }
      ul.magazine li .ttl {
        font-size: 14px;
        padding: 20px;
        line-height: 1.3;
        margin-top: -0.25em;
        margin-bottom: -0.25em;
        font-weight: bold;
        letter-spacing: .1em; }
      ul.magazine li .date {
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        line-height: 1;
        font-size: 11px;
        margin: 20px; }
  /* !! - - -  */
  /* !! ニュース */
  ul.news {
    padding: 20px;
    overflow: auto;
    height: 300px;
    border: 1px solid #ccc;
    margin: 30px 0px; }
    ul.news li {
      position: relative;
      padding-left: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px; }
      ul.news li:last-of-type {
        margin-bottom: 0; }
    ul.news .date {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0; }
    ul.news .cat {
      position: relative;
      left: 0;
      background: #eee;
      width: 100px;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: center; }
    ul.news .ttl {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 10px; }
    ul.news .desc {
      font-size: 12px;
      line-height: 1.6;
      margin-top: -0.3em;
      margin-bottom: -0.3em; }
  /* !! - - -  */
  /* !! ページフッター */
  #page-footer {
    margin-top: 50px;
    padding-bottom: 0 !important;
    display: block;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 12px; }
    #page-footer > div {
      width: 100%;
      border-bottom: 1px dotted #ccc; }
      #page-footer > div.nb {
        border-bottom: 0 !important; }
      #page-footer > div .hd {
        background: #26B7BC;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-weight: bold;
        position: relative;
        font-size: 15px;
        letter-spacing: .1em;
        position: relative;
        margin-bottom: 15px; }
        #page-footer > div .hd:after {
          content: "";
          width: 13px;
          height: 13px;
          position: absolute;
          left: 50%;
          bottom: 0;
          background: #26B7BC;
          -webkit-transform: translate(-50%, 50%) rotate(45deg);
          -moz-transform: translate(-50%, 50%) rotate(45deg);
          -ms-transform: translate(-50%, 50%) rotate(45deg);
          -o-transform: translate(-50%, 50%) rotate(45deg);
          transform: translate(-50%, 50%) rotate(45deg); }
    #page-footer ul {
      padding: 15px; }
      #page-footer ul.fix li {
        padding: 10px; }
      #page-footer ul li {
        padding: 14px 0;
        border-bottom: 1px dotted #ccc;
        white-space: nowrap; }
        #page-footer ul li:last-of-type {
          border-bottom: 0; }
        #page-footer ul li.bb {
          border-bottom: 1px dotted #ccc !important; }
        #page-footer ul li .red {
          color: #df002d;
          font-weight: bold; }
        #page-footer ul li.rel1 {
          box-sizing: content-box; }
        #page-footer ul li.idx {
          width: 100%; }
          #page-footer ul li.idx p {
            font-size: 12px;
            width: 84px;
            text-align: center;
            padding: 10 0px;
            border: 1px dotted #ccc; }
        #page-footer ul li a:not(.inline) {
          display: block; }
        #page-footer ul li i {
          background: #26B7BC;
          color: #fff;
          padding: 5px;
          border-radius: 4px;
          margin-right: 5px; }
        #page-footer ul li b {
          display: block;
          margin-bottom: 5px; }
        #page-footer ul li .img {
          width: 127px; }
        #page-footer ul li img {
          width: 100% !important;
          height: auto !important;
          display: block; }
  .txt_bnr_area {
    display: none; }
  /* !!------------------------------------ */
  /* !! フッター */
  #footer {
    margin-top: 0 !important;
    font-size: 12px; }
  .container-fluid > ul {
    margin-bottom: 10px; }
    .container-fluid > ul li {
      display: block !important;
      text-align: left;
      border-bottom: 1px solid #ededed; }
      .container-fluid > ul li a {
        display: block;
        padding: 10px 0; }
  .footer_logo_area {
    margin-top: 20px; }
    .footer_logo_area p.logo {
      text-align: center; }
    .footer_logo_area a {
      display: block;
      margin-bottom: 10px; }
      .footer_logo_area a img {
        display: inline-block;
        width: 150px;
        height: auto;
        vertical-align: middle; }
      .footer_logo_area a span {
        vertical-align: middle;
        font-size: 1.2rem;
        margin-left: 10px; }
    .footer_logo_area .copyright {
      text-align: center !important; }
  /* !!------------------------------------ */
  /* !! 下層共通 */
  main.page h1.hd {
    margin: 0px 0 30px 0; }
  main.page h2 {
    height: 50px;
    border: 1px solid #3e3a39;
    border-width: 1px 0;
    line-height: 50px;
    margin-bottom: 8.01vw;
    margin-top: 50px; }
    main.page h2:first-of-type {
      margin-top: 0; }
  main.page h3 {
    color: #26B7BC;
    border: 1px solid #26B7BC;
    text-align: center;
    padding: 10px;
    max-width: 600px;
    margin: 30px auto;
    line-height: 1.5;
    font-weight: normal; }
  /* !!------------------------------------ */
  /* !! ご注文コースについて */
  main.page.course {
    text-align: center; }
    main.page.course .img {
      text-align: center; }
      main.page.course .img .caption {
        line-height: 1.8;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding-top: 30px;
        margin-top: -0.4em;
        margin-bottom: -0.4em; }
    main.page.course ul.merit {
      display: block;
      max-width: 880px;
      margin: 0 auto; }
      main.page.course ul.merit li {
        font-size: 20px;
        line-height: 1;
        width: 100%;
        position: relative;
        padding-left: 16.02vw;
        line-height: 1;
        margin-top: 5.34vw;
        white-space: normal;
        text-align: left;
        padding-right: 21.36vw; }
        main.page.course ul.merit li > img {
          width: 13.35vw;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0); }
          main.page.course ul.merit li > img.dec-r {
            left: auto;
            right: 0;
            position: absolute;
            top: 0; }
        main.page.course ul.merit li .txt {
          display: block;
          line-height: 1.4;
          text-align: justify; }
        main.page.course ul.merit li span {
          font-size: 0.56em;
          line-height: 1.3;
          display: block;
          margin-bottom: 5px; }
        main.page.course ul.merit li .dec {
          display: inline-block;
          width: 16.02vw;
          vertical-align: top;
          position: absolute;
          top: 0;
          right: 0; }
          main.page.course ul.merit li .dec img {
            top: 0%;
            width: 100%;
            height: auto;
            display: block;
            position: relative;
            -webkit-transform: translateY(0%);
            -moz-transform: translateY(0%);
            -ms-transform: translateY(0%);
            -o-transform: translateY(0%);
            transform: translateY(0%); }
      main.page.course ul.merit + .box {
        text-align: center;
        font-size: 14px;
        margin-top: 8.01vw; }
    main.page.course .div {
      margin-top: 20px; }
    main.page.course .box {
      text-align: justify;
      padding: 20px;
      border: 1px solid #3e3a39;
      max-width: 900px;
      margin: 20px auto;
      position: relative;
      overflow: hidden; }
      main.page.course .box h4 {
        font-size: 20px;
        color: #26B7BC;
        line-height: 1;
        margin-bottom: 10px; }
      main.page.course .box p {
        font-size: 14px;
        line-height: 1.75; }
      main.page.course .box p.note {
        padding-left: 1.4em;
        position: relative; }
        main.page.course .box p.note i {
          font-style: normal;
          position: absolute;
          top: 0;
          left: 0; }
      main.page.course .box:after {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        background: #26B7BC;
        z-index: 1;
        -webkit-transform: translate(50%, -50%) rotate(45deg);
        -moz-transform: translate(50%, -50%) rotate(45deg);
        -ms-transform: translate(50%, -50%) rotate(45deg);
        -o-transform: translate(50%, -50%) rotate(45deg);
        transform: translate(50%, -50%) rotate(45deg); }
    main.page.course p.note {
      padding-left: 1.4em;
      position: relative;
      max-width: 900px;
      margin: 0 auto; }
      main.page.course p.note i {
        font-style: normal;
        position: absolute;
        top: 0;
        left: 0; }
    main.page.course p.otamesi {
      font-size: 20px;
      text-align: center;
      margin: 0 8.01vw; }
      main.page.course p.otamesi strong {
        color: #e4007f !important;
        display: inline-block;
        line-height: 1.3;
        position: relative; }
        main.page.course p.otamesi strong:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: -10px;
          -webkit-transform: rotate(-20deg) translateY(-50%);
          -moz-transform: rotate(-20deg) translateY(-50%);
          -ms-transform: rotate(-20deg) translateY(-50%);
          -o-transform: rotate(-20deg) translateY(-50%);
          transform: rotate(-20deg) translateY(-50%);
          width: 9px;
          height: 53px;
          background-image: url(../../img/course/deco@2x.png);
          background-repeat: no-repeat;
          -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center center; }
        main.page.course p.otamesi strong:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          right: -10px;
          -webkit-transform: rotate(20deg) translateY(-50%);
          -moz-transform: rotate(20deg) translateY(-50%);
          -ms-transform: rotate(20deg) translateY(-50%);
          -o-transform: rotate(20deg) translateY(-50%);
          transform: rotate(20deg) translateY(-50%);
          width: 9px;
          height: 53px;
          background-image: url(../../img/course/deco@2x.png);
          background-repeat: no-repeat;
          -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center center; }
    main.page.course .desc {
      text-align: center;
      line-height: 1;
      margin-top: 50px; }
  /* !!------------------------------------ */
  /* !! 良くあるご質問 */
  main.FAQ dl {
    margin: 0 auto; }
    main.FAQ dl dt {
      margin-bottom: 5.34vw;
      line-height: 1.3;
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;
      font-size: 15px;
      font-weight: bold;
      color: #26B7BC;
      border: 1px solid #26B7BC;
      position: relative;
      cursor: pointer;
      text-align: justify; }
      main.FAQ dl dt:before {
        content: "Q.";
        line-height: 1;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -3px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        font-family: urw-din-semi-condensed, sans-serif;
        font-weight: bold;
        font-size: 36px; }
      main.FAQ dl dt:after {
        content: "＋";
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        line-height: 1;
        font-weight: bold;
        font-size: 24px; }
      main.FAQ dl dt.opened:after {
        content: "ー" !important; }
    main.FAQ dl dd {
      padding-left: 50px;
      padding-right: 0px;
      margin: 5.34vw 0;
      display: none;
      position: relative; }
      main.FAQ dl dd p {
        font-size: 14px;
        line-height: 1.75; }
      main.FAQ dl dd a {
        font-size: 14px; }
      main.FAQ dl dd:before {
        content: "A.";
        color: #bbb;
        line-height: 1;
        position: absolute;
        left: 15px;
        top: 0;
        margin-top: -5px;
        font-family: urw-din-semi-condensed, sans-serif;
        font-weight: bold;
        font-size: 36px; }
  /* !!------------------------------------ */
  /* !! お支払いについて */
  main.payment .table-list li {
    position: relative;
    padding: 0;
    padding-right: 0;
    font-size: 16px;
    border: 1px solid #26B7BC;
    margin-bottom: 20px;
    padding-left: 0;
    line-height: 1.81; }
    main.payment .table-list li p {
      line-height: 1.81;
      margin: 10px;
      font-size: 12px; }
    main.payment .table-list li h3 {
      color: #3e3a39;
      font-weight: bold;
      letter-spacing: .1em;
      max-width: 100%;
      border: 0;
      padding: 0;
      font-size: 16px;
      padding-bottom: 5px !important;
      line-height: 1.4;
      text-align: left;
      margin: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      margin-top: 20px; }
      main.payment .table-list li h3:first-of-type {
        margin-top: 10px; }
    main.payment .table-list li > span {
      display: block;
      padding: 10px;
      position: static;
      top: 0;
      left: 0;
      height: 100%;
      background: #26B7BC;
      width: 100%;
      color: #fff;
      font-weight: bold; }
  main.payment p.img {
    margin: 30px 0; }
    main.payment p.img.cresit, main.payment p.img.convenience {
      padding-left: 30px; }
  /* !!------------------------------------ */
  /* !!  クロワールシリーズ一覧 */
  .btn2 {
    height: 50px;
    border-radius: 25px;
    width: 70%;
    margin-top: 30px !important;
    font-size: 15px; }
    .btn2:after {
      right: 15px !important; }
  main.product {
    padding: 0px; }
    main.product .main_visual {
      display: none; }
      main.product .main_visual .item img {
        width: 100% !important;
        height: auto !important; }
    main.product .mv {
      background: none;
      height: 53.4vw;
      margin-top: -8.01vw; }
      main.product .mv p {
        width: 90% !important;
        height: auto;
        margin-top: -8.01vw; }
    main.product #croire-list.croire {
      width: 100%;
      padding: 10px; }
      main.product #croire-list.croire .cell {
        padding: 8.01vw 4.005vw !important;
        height: auto; }
        main.product #croire-list.croire .cell .inner {
          z-index: 1;
          position: relative;
          display: block;
          padding: 0 !important;
          background-size: 100% auto !important;
          background-position: top right !important; }
          main.product #croire-list.croire .cell .inner .img {
            width: 40.05vw;
            position: static;
            transform: none;
            margin: 0 auto; }
          main.product #croire-list.croire .cell .inner .data {
            margin-left: 2.67vw;
            display: block;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            display: block !important;
            justify-content: center;
            align-content: flex-start;
            align-items: flex-start;
            margin-top: 15px; }
            main.product #croire-list.croire .cell .inner .data .ttl {
              line-height: 1.4;
              text-align: justify;
              font-size: 5.874vw; }
              main.product #croire-list.croire .cell .inner .data .ttl span {
                white-space: normal; }
            main.product #croire-list.croire .cell .inner .data .base_price {
              text-align: right; }
              main.product #croire-list.croire .cell .inner .data .base_price p {
                margin: 0;
                width: 100%;
                text-align: right; }
          main.product #croire-list.croire .cell .inner ul.idx {
            width: 100%;
            display: block; }
            main.product #croire-list.croire .cell .inner ul.idx li {
              margin-top: 15px;
              width: 100%;
              padding-bottom: 25px !important; }
            main.product #croire-list.croire .cell .inner ul.idx .txt {
              text-align: center; }
            main.product #croire-list.croire .cell .inner ul.idx .onestop .txt {
              display: none; }
            main.product #croire-list.croire .cell .inner ul.idx .onestop .price {
              margin-top: 30px; }
        main.product #croire-list.croire .cell:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #F5F4F6;
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          background-size: 100% auto !important;
          background-position: top right !important; }
        main.product #croire-list.croire .cell.dhaepa:after, main.product #croire-list.croire .cell.na:after {
          background-position: bottom right !important; }
      main.product #croire-list.croire .na {
        display: block !important; }
  /* !! - - -  */
  /* !! ポップアップ */
  #shade {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 10;
    width: 110vw;
    height: 140vh;
    opacity: 0;
    pointer-events: none;
    transition: 300ms;
    background: rgba(0, 0, 0, 0.3); }
    body.popup #shade {
      opacity: 1;
      pointer-events: auto; }
  #popup {
    width: 100%;
    top: 0;
    left: 0;
    -webkit-transform: translate(0, 100%);
    -moz-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    -o-transform: translate(0, 100%);
    transform: translate(0, 100%);
    padding: 20px 20px; }
    body.popup #popup {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      height: 100%; }
    #popup .close {
      top: 20px;
      right: 20px; }
    #popup h2 {
      margin-top: 2px;
      font-size: 20px; }
      #popup h2:after {
        margin: 20px 0; }
    #popup ul.idx {
      font-size: 12px;
      margin-bottom: 20px; }
      #popup ul.idx li {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 26.7vw;
        height: 21.36vw;
        border: 1px solid #26B7BC;
        border-radius: 8px;
        line-height: 1.6;
        font-weight: bold;
        color: #26B7BC; }
    #popup h3 {
      line-height: 1;
      font-size: 14px;
      letter-spacing: .1em;
      padding-bottom: 10px;
      border-bottom: 1px solid #26B7BC;
      margin-top: 10px; }
      #popup h3 i {
        color: #26B7BC;
        padding-right: 4px; }
    #popup p {
      letter-spacing: 0;
      font-size: 12px;
      margin-top: 10px; }
    #popup #popup-inner {
      height: 100%;
      overflow: auto; }
    #popup #teiki-link-btn {
      margin: 0 auto;
      margin-top: 20px;
      width: 100%; }
    #popup .teiki, #popup .onestop {
      display: none; }
      #popup .teiki.on, #popup .onestop.on {
        display: block; }
    #popup .onestop {
      padding-top: 50px; }
      #popup .onestop > div {
        border-bottom: 1px solid #9FA0A0;
        height: auto !important; }
        #popup .onestop > div p {
          text-align: center; }
          #popup .onestop > div p.price {
            height: auto;
            justify-content: center; }
            #popup .onestop > div p.price span {
              height: 36px;
              margin: 0; }
            #popup .onestop > div p.price .price_1 {
              align-self: flex-start !important; }
            #popup .onestop > div p.price .btn2 {
              margin: 0 auto !important;
              margin-top: 20px !important; }
        #popup .onestop > div:last-of-type {
          margin-bottom: 0; }
        #popup .onestop > div._1 {
          border-bottom: 1px solid #EE869A; }
          #popup .onestop > div._1 .hd {
            background: #EE869A; }
        #popup .onestop > div._2 img {
          opacity: 0; }
      #popup .onestop .hd {
        height: 30px;
        margin-bottom: 20px;
        background: #9FA0A0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #FFF;
        letter-spacing: .1em;
        font-weight: 600; }
  /* !!------------------------------------ */
  /* !! 商品詳細画面 */
  #item_photo_area .slick-slide img {
    max-height: 260px;
    width: auto !important; }
  #page_product_detail #main {
    padding: 0 8.01vw; }
  /* !! iframe */
  iframe.detail {
    display: block;
    width: 100%; }
  #site-header {
    display: none !important; }
  main.single {
    margin-top: 0px;
    position: relative;
    z-index: 1; }
    main.single > section {
      padding: 0; }
    main.single h2 {
      padding: 10px 0;
      font-size: 24px;
      height: auto;
      padding-bottom: 10px; }
    main.single .mv {
      background: #ccc;
      height: 400px;
      overflow: hidden;
      -webkit-background-size: cover;
      background-size: cover !important;
      margin: 0; }
      main.single .mv .inner {
        position: relative;
        height: auto;
        max-width: 100% !important;
        height: 260px;
        margin: 70px auto !important; }
        main.single .mv .inner .img {
          float: right;
          margin-right: 50px; }
      main.single .mv .data {
        height: 260px;
        float: right;
        margin-right: 50px;
        position: relative; }
      main.single .mv h1 {
        text-align: right;
        font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
        font-size: 2.4rem;
        font-weight: normal;
        margin-top: -0.355em;
        margin-bottom: -0.355em;
        line-height: 1.71;
        letter-spacing: .1em;
        position: relative;
        top: 82px; }
      main.single .mv .logo {
        position: absolute;
        bottom: 0;
        right: 0; }
    main.single .lead {
      padding: 100px 0;
      text-align: center;
      max-width: 1000px;
      margin: 0 auto;
      padding: 100px 0; }
      main.single .lead h2 {
        line-height: 1.7;
        font-size: 24px;
        padding-bottom: 10px; }
      main.single .lead p {
        text-align: center !important;
        margin-top: -0.4em;
        margin-bottom: -0.4em; }
    main.single section.about {
      overflow: hidden;
      background: #EAF6FD; }
      main.single section.about .inner {
        padding: 0;
        max-width: 1000px;
        margin: 100px auto !important; }
        main.single section.about .inner h2.Q {
          line-height: 1.7;
          text-align: center;
          border-bottom: 1px solid #BDBAB9;
          padding-bottom: 10px;
          margin-bottom: 0 !important; }
        main.single section.about .inner p.A {
          padding-top: 20px;
          text-align: justify;
          margin-top: -0.375em;
          margin-bottom: -0.375em; }
    main.single h2.bd {
      line-height: 1.7;
      text-align: center !important;
      height: auto;
      border-bottom: 1px solid #BDBAB9;
      padding: 10px 0;
      padding-bottom: 10px;
      margin-bottom: 0 !important;
      margin-top: 50px !important; }
    main.single > section .inner {
      max-width: 1000px;
      margin: 0 auto;
      padding: 100px 0; }
      main.single > section .inner h2 {
        line-height: 1.7;
        text-align: center;
        margin-top: -0.375em;
        margin-bottom: -0.375em;
        margin: 100px 0 50px 0; }
        main.single > section .inner h2:first-of-type {
          margin-top: 0; }
    main.single h3.border {
      max-width: 383px;
      line-height: 38px;
      border: 1px solid #3e3a39;
      text-align: center;
      border-radius: 20px;
      font-size: 2rem;
      font-weight: bold;
      margin: 50px auto; }
    main.single ul.img-list {
      font-size: 0;
      text-align: center; }
      main.single ul.img-list._50 li {
        margin: 0 25px; }
      main.single ul.img-list li {
        width: 150px;
        margin: 0 15px;
        margin-bottom: 30px;
        display: inline-block;
        vertical-align: top; }
        main.single ul.img-list li .img {
          width: 100%;
          height: 150px;
          overflow: hidden;
          background: #eee;
          border-radius: 50%; }
          main.single ul.img-list li .img img {
            width: 100% !important;
            height: auto !important;
            display: block; }
        main.single ul.img-list li span {
          text-align: center;
          display: block;
          margin-top: 10px;
          font-size: 1.6rem;
          width: 200%;
          left: 50%;
          letter-spacing: .05em;
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          position: relative; }
          main.single ul.img-list li span b {
            display: block;
            font-size: 1.13em; }
            main.single ul.img-list li span b i {
              font-size: 1.22em; }
      main.single ul.img-list.cen span {
        text-align: center !important; }
      main.single ul.img-list.mid li {
        width: 195px;
        vertical-align: top; }
        main.single ul.img-list.mid li .img {
          height: auto; }
        main.single ul.img-list.mid li span {
          width: 100% !important;
          left: 0;
          -webkit-transform: translate(0);
          -moz-transform: translate(0);
          -ms-transform: translate(0);
          -o-transform: translate(0);
          transform: translate(0);
          text-align: justify; }
      main.single ul.img-list.lg li {
        width: 250px;
        vertical-align: top; }
        main.single ul.img-list.lg li:nth-last-child(-n+3) {
          margin-bottom: 0; }
        main.single ul.img-list.lg li .img {
          height: auto; }
        main.single ul.img-list.lg li span {
          padding-top: 20px;
          margin-top: 20px;
          margin-top: -0.35em;
          margin-bottom: -0.35em; }
    main.single ul.img-list2 {
      display: flex;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap; }
      main.single ul.img-list2 li {
        width: calc((100% - 15px)/2);
        margin-bottom: 10px; }
        main.single ul.img-list2 li .img {
          background: #fff;
          height: auto;
          position: relative; }
          main.single ul.img-list2 li .img img {
            width: 100% !important;
            height: auto !important; }
          main.single ul.img-list2 li .img i {
            position: absolute;
            bottom: 0;
            left: 0;
            line-height: 22px;
            background: #FACD00;
            width: 110px;
            text-align: center;
            font-size: 1.6rem;
            z-index: 1; }
        main.single ul.img-list2 li p {
          font-size: 1.6rem; }
          main.single ul.img-list2 li p b {
            display: block;
            font-size: 1.13em;
            line-height: 1;
            margin: 20px 0 10px 0; }
    main.single section.bg-GR {
      background: #EFEFEF;
      overflow: hidden; }
      main.single section.bg-GR .inner {
        max-width: 1000px;
        margin: 0 auto; }
    main.single .img img {
      width: 100% !important;
      height: auto !important; }
    main.single .note {
      font-size: 1.4rem; }
    main.single table.nutrient {
      table-layout: fixed;
      width: 100% !important;
      height: auto !important;
      border-collapse: collapse;
      position: relative; }
      main.single table.nutrient caption {
        font-size: .8em;
        letter-spacing: .1em;
        position: absolute;
        top: -2em;
        right: 0; }
      main.single table.nutrient th, main.single table.nutrient td {
        border: 1px solid #BDBAB9;
        padding: 6px;
        font-size: 1.4rem !important; }
      main.single table.nutrient th {
        background: #EAF6FD;
        width: auto; }
      main.single table.nutrient td {
        font-size: 1.4rem;
        text-align: right; }
      main.single table.nutrient + table.material {
        margin-top: 50px; }
    main.single table.material {
      margin-top: 100px;
      width: 100%;
      border-collapse: collapse; }
      main.single table.material th, main.single table.material td {
        border: 1px solid #BDBAB9;
        padding: 6px; }
      main.single table.material th {
        background: #EAF6FD;
        font-size: 1.4rem;
        width: auto; }
      main.single table.material td {
        font-size: 1.4rem; }
    main.single p.hd {
      line-height: 50px;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: .1em;
      border-radius: 25px; }
      main.single p.hd.box {
        border-radius: 0; }
      main.single p.hd.border {
        border: 1px solid #3e3a39; }
    main.single section .inner > div.box {
      padding: 8.01vw 0 !important;
      border-bottom: 1px dotted #333; }
    main.single div.img-left {
      position: relative;
      margin: 0; }
      main.single div.img-left .img {
        width: 80%;
        height: auto;
        margin: 0 auto; }
        main.single div.img-left .img img {
          width: 100% !important;
          height: auto !important;
          display: block; }
      main.single div.img-left.block .img {
        width: 70%;
        height: auto;
        margin-left: 0; }
        main.single div.img-left.block .img .caption {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 10px !important; }
        main.single div.img-left.block .img._100 {
          width: 100% !important;
          height: auto !important; }
      main.single div.img-left.block .txt {
        left: 0;
        width: 100%;
        padding-left: 0;
        margin-top: 15px; }
      main.single div.img-left .txt {
        position: static;
        text-align: left;
        top: 0;
        margin-top: 20px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
        main.single div.img-left .txt h2 {
          text-align: left;
          margin: 0;
          line-height: 1;
          margin-bottom: 20px; }
        main.single div.img-left .txt p {
          text-align: left;
          margin-top: -0.35em;
          margin-bottom: -0.35em; }
    main.single div.img-right {
      position: relative; }
      main.single div.img-right .img {
        padding-left: 0;
        text-align: center;
        width: 70%;
        margin: 0 auto; }
        main.single div.img-right .img img {
          width: 100% !important;
          height: auto !important; }
      main.single div.img-right .txt {
        margin-top: 20px;
        position: relative;
        text-align: left;
        left: 0;
        width: 100%;
        top: 0;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
        main.single div.img-right .txt h3 {
          margin: 0;
          line-height: 1;
          font-size: 2rem;
          text-align: center;
          letter-spacing: .1em;
          font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
          font-weight: normal;
          margin-bottom: 16px; }
        main.single div.img-right .txt p {
          text-align: left;
          margin-top: -0.35em;
          margin-bottom: -0.35em; }
  #content-detail {
    padding: 0 5.34vw !important; }
    #content-detail > section > .inner {
      margin: 0 !important;
      padding: 30px 0; }
      #content-detail > section > .inner h2 {
        height: auto;
        font-size: 16px;
        margin: 30px 0 !important; }
        #content-detail > section > .inner h2:first-of-type {
          margin-top: 0 !important; }
    #content-detail .mv {
      height: auto;
      margin-left: -5.34vw !important;
      width: 100vw !important;
      padding: 0;
      background-size: auto 200px !important;
      background-position: left top;
      background-color: #fff; }
      #content-detail .mv .inner {
        height: auto;
        margin: 8.01vw 0 !important;
        margin-top: 32.04vw !important; }
      #content-detail .mv .img {
        float: none;
        margin: 0 auto; }
        #content-detail .mv .img img {
          display: block;
          margin: 0 auto;
          width: 60% !important;
          height: auto !important; }
      #content-detail .mv .data {
        margin-right: 0;
        float: none;
        height: auto;
        text-align: center; }
        #content-detail .mv .data img {
          position: static;
          margin-right: auto;
          height: 40px !important;
          width: auto !important; }
      #content-detail .mv h1 {
        text-align: center;
        font-size: 18px;
        padding: 5.34vw 5.34vw;
        white-space: normal;
        top: 0; }
    #content-detail .lead {
      padding: 30px 0; }
      #content-detail .lead h2 {
        height: auto;
        font-size: 16px; }
      #content-detail .lead p {
        padding: 0 5.34vw;
        text-align: justify !important; }
    #content-detail .fitW {
      margin-left: -5.34vw !important;
      width: 100vw !important; }
      #content-detail .fitW .inner {
        margin-top: 0 !important;
        padding: 5.34vw !important; }
    #content-detail .about {
      margin-left: -5.34vw !important;
      width: 100vw !important; }
      #content-detail .about .inner {
        margin-top: 0 !important;
        padding: 5.34vw !important; }
      #content-detail .about h2 {
        margin-top: 0 !important;
        font-size: 16px; }
      #content-detail .about p.A {
        padding: 5.34vw; }
      #content-detail .about .inner {
        padding: 0 5.34vw; }
      #content-detail .about .idx.rev.graf {
        display: block;
        max-width: 100%; }
        #content-detail .about .idx.rev.graf img {
          max-width: 100%;
          height: auto; }
        #content-detail .about .idx.rev.graf p {
          position: static;
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0);
          max-resolution: 20px; }
    #content-detail ul.img-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -o-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 8.01vw; }
      #content-detail ul.img-list li {
        width: calc(50% - 10px) !important;
        margin: 0;
        margin-bottom: 15px; }
        #content-detail ul.img-list li .img {
          height: auto; }
  /* !!------------------------------------ */
  /* !! ★プレミアムクロワール茶 */
  main.premium-croire .mv {
    background-image: url(../../product/img/bg01.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  main.premium-croire .img.sod {
    text-align: center; }
    main.premium-croire .img.sod img {
      width: 100% !important;
      height: auto !important; }
  main.premium-croire .idx.spDb {
    display: block; }
    main.premium-croire .idx.spDb img {
      width: 100% !important;
      height: auto !important; }
  main.premium-croire .desc {
    text-align: center;
    margin-top: 50px; }
  main.premium-croire .result {
    position: relative;
    padding-right: 0; }
    main.premium-croire .result .img {
      position: relative;
      top: 0;
      right: 0;
      border: 1px solid #BDBAB9; }
    main.premium-croire .result .note {
      margin-top: 10px; }
    main.premium-croire .result h3 {
      color: #fff;
      line-height: 40px;
      background: #B81C22;
      font-size: 2rem;
      padding-left: 15px;
      margin: 50px 0 30px 0; }
    main.premium-croire .result .red {
      color: #B81C22;
      font-size: 1.6rem;
      position: relative;
      padding-right: 65%;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 20px; }
      main.premium-croire .result .red img {
        width: 60%;
        height: auto;
        position: absolute;
        top: 0;
        right: 0; }
  main.premium-croire .img._70p {
    width: 70%;
    margin: 0 auto; }
  main.premium-croire .idx {
    display: flex;
    justify-content: space-between; }
  main.premium-croire .material {
    margin-top: 50px !important; }
  /* !!------------------------------------ */
  /* !! ★クロワールアイ・プロ */
  main.croire-eye-pro .mv {
    background-image: url(../../product/img/bg02.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  main.croire-eye-pro .idx {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    main.croire-eye-pro .idx.rev {
      flex-direction: row-reverse; }
  main.croire-eye-pro .graf {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px; }
    main.croire-eye-pro .graf .txt {
      width: auto;
      height: auto; }
      main.croire-eye-pro .graf .txt p {
        position: relative;
        top: 50%;
        margin-top: -20px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
  main.croire-eye-pro .img.ac {
    text-align: center;
    max-width: 100%;
    padding: 0; }
    main.croire-eye-pro .img.ac img {
      width: 100% !important;
      height: auto !important; }
  main.croire-eye-pro p.ac {
    text-align: center;
    padding: 5.34vw; }
  main.croire-eye-pro h2.bd + p {
    padding-top: 30px;
    margin-top: -0.35em;
    margin-bottom: -0.35em; }
  main.croire-eye-pro img.KEMIN {
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 20px; }
  main.croire-eye-pro .mt30 {
    margin-top: 30px; }
  main.croire-eye-pro .idx.box {
    padding: 0 !important; }
    main.croire-eye-pro .idx.box dl {
      max-width: 475px;
      margin: 0;
      margin-top: 20px; }
      main.croire-eye-pro .idx.box dl dt {
        position: relative;
        text-align: center;
        padding-top: 20px; }
        main.croire-eye-pro .idx.box dl dt span {
          position: absolute;
          top: 0;
          left: 0;
          font-weight: bold;
          font-size: 2rem; }
        main.croire-eye-pro .idx.box dl dt img {
          width: 100% !important;
          height: auto !important; }
      main.croire-eye-pro .idx.box dl dd {
        font-size: 1.6rem;
        padding-top: 20px;
        margin-top: -0.35em;
        margin-bottom: -0.35em; }
  main.croire-eye-pro ul.img-list._200 {
    max-width: 100%;
    width: 100%;
    margin: 0 auto; }
    main.croire-eye-pro ul.img-list._200 li {
      width: calc(50% - 5px) !important;
      margin: 0x;
      margin-bottom: 30px; }
      main.croire-eye-pro ul.img-list._200 li:nth-last-child(-n+3) {
        margin-bottom: 0; }
      main.croire-eye-pro ul.img-list._200 li b {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        display: block;
        margin-bottom: 20px; }
      main.croire-eye-pro ul.img-list._200 li .img {
        height: auto; }
  main.croire-eye-pro ul.more-elem {
    font-size: 0;
    text-align: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap; }
    main.croire-eye-pro ul.more-elem li {
      display: inline-block;
      letter-spacing: .1em;
      margin: 0px;
      line-height: 60px;
      padding: 0 10px;
      min-width: 30%;
      flex-grow: 2;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold; }
  /* !!------------------------------------ */
  /* !! ★クロワールアイゴールド */
  main.croire-gold .mv {
    background-image: url(../../product/img/bg06.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  /* !!------------------------------------ */
  /* !! ★クロワールアイ・ルテイン+ゼアキサンチンゼリー */
  main.croire-eye-lutein .mv {
    background-image: url(../../product/img/bg02.jpg);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: center center; }
  main.croire-eye-lutein ul.img-list.mid.cen li {
    width: 213px; }
    main.croire-eye-lutein ul.img-list.mid.cen li span {
      white-space: nowrap; }
  /* !!------------------------------------ */
  /* !! ★還元型コエンザイムQ10 */
  main#content-detail.Q10 {
    overflow: hidden; }
    main#content-detail.Q10 .mv {
      background-image: url(../../product/Q10/img/mv-bg.jpg);
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-position: top center; }
      main#content-detail.Q10 .mv img.logo.Q10 {
        height: 60px !important; }
    main#content-detail.Q10 p.A {
      padding: 0; }
    main#content-detail.Q10 .img._100 {
      width: 100% !important; }
    main#content-detail.Q10 div.img-right.block {
      margin-top: 20px; }
      main#content-detail.Q10 div.img-right.block .img {
        width: 100%; }
      main#content-detail.Q10 div.img-right.block .txt {
        padding-right: 0px; }
      main#content-detail.Q10 div.img-right.block h3 {
        line-height: 1.75;
        margin-top: -0.375em;
        margin-bottom: -0.375em;
        padding-bottom: 10px; }
      main#content-detail.Q10 div.img-right.block h4 {
        margin-top: 10px;
        font-size: 2rem;
        line-height: 1.7;
        margin-bottom: 10px; }
    main#content-detail.Q10 div.img-left.block .txt {
      margin-top: 15px; }
    main#content-detail.Q10 .img-list li {
      margin-bottom: 0 !important; }
    main#content-detail.Q10 p.ac {
      text-align: justify; }
    main#content-detail.Q10 ul.img-list.food {
      max-width: 600px;
      margin: 0 auto; }
      main#content-detail.Q10 ul.img-list.food li {
        margin: 0 !important;
        margin-bottom: 10px !important; }
        main#content-detail.Q10 ul.img-list.food li:nth-last-of-type(-n+3) {
          margin-bottom: 0 !important; }
    main#content-detail.Q10 p.hd {
      font-size: 2rem;
      line-height: 1;
      margin-bottom: 20px; }
      main#content-detail.Q10 p.hd strong {
        font-size: 1.2em;
        font-weight: 600;
        display: block;
        margin-bottom: 20px; }
    main#content-detail.Q10 ul.idx.vitamin {
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-bottom: 0px !important; }
      main#content-detail.Q10 ul.idx.vitamin li {
        max-width: 415px;
        display: inline-block;
        width: 100%;
        height: 80px;
        position: relative;
        margin: 0px;
        margin-bottom: 30px;
        border: 1px solid #fff;
        border-radius: 10px; }
        main#content-detail.Q10 ul.idx.vitamin li p {
          text-align: center;
          font-size: 2.4rem;
          font-weight: 600;
          line-height: 1.33;
          position: relative;
          color: #fff;
          top: 50%;
          margin-top: -0.165em;
          margin-bottom: -0.165em;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); }
          main#content-detail.Q10 ul.idx.vitamin li p span {
            display: blok;
            font-size: 0.5em; } }
