@charset "UTF-8";
/* CSS Document */


$font01 : 'DIN','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','YuGothic', 'Yu Gothic','游ゴシック','メイリオ','Meiryo',sans-serif;

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul , ol {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section{
	display: block;
}

strong {
	font-weight: normal;
}

form {
	margin-bottom: 0;
}

img {
	vertical-align:bottom;
}

.na{
	display: none !important;
}

::selection {
	color: #fff;
	background: #00aebb;
}

::-moz-selection {
	color: #fff;
	//background: #00aebb;
}

input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

.collection main{
	overflow: hidden;
}

.noLink{
	text-decoration: none !important;
	cursor: default;
}

p{
	line-height: 1.6;
	//@include gothic;
	font-size: 16px;
	text-align: justify;
}

.ar{
	text-align: right;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}


.idx{
	display: flex;
	justify-content: space-between;

}

.note{
	@include note(1.2em);
}

body{
	.test{
		display: none !important;
	}
}

html.test{
	.test{
		display: block !important;
	}
	#crowari .test{
		display: -webkit-flex !important;
		display: -moz-flex !important;
		display: -ms-flex !important;
		display: -o-flex !important;
		display: flex !important;
	}
	.live{
		display: none !important;
	}
}

@media screen and (max-width: 767px) {
	img{
		height: auto;
	}
}

/* !!------------------------------------ */
/* !! 共通css */
html{
	font-size: 62.5%;
}

body{
	margin: 0;
	padding: 0;
	color: $text;
	background: #fff;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	@include gothic;
}

main{
	@include gothic;
	>section{
		padding-top: 100px;
	}
}

/* !!------------------------------------ */
/* !! ヘッダー */
.header_logo_area{
	.copy{
		font-size: 1.2rem !important;
	}
	img{
		width: 294px;
		height: auto;
		display: inline-block;
		vertical-align: middle;
	}
	span{
		display: inline-block;
		font-size: 1.3rem;
		vertical-align: middle;
		margin-left: 10px;
	}
}


#category_id{
	appearance: none !important;
	display: none !important;
}

#category{
	//display: none !important;
}




ul.category-nav{
	@media screen and (min-width: 768px) {
		max-width: 1118px;
		margin: 30px auto;
		height: 50px;
		border: 1px solid #000;
		border-width: 1px 0;
		font-size: 0;
		text-align: center;
		li{
			margin:0 !important;
			display: inline-block;
			a{
				font-size: 16px;
				padding: 0 15px;
			}
		}
	}
	
}//ul.category-nav


.theme_main_only{
	overflow: hidden;
}

/* !!------------------------------------ */
/* !! トップページ */
.slick-slide > img{
	width: 100% !important;
	height: auto !important;
}


h1.hd,
h2{
	font-size: 14px;
	@include gothic;
	text-align: center;
	i{
		font-size: 32px;
		@include din;
		font-weight: 300;
	}
}

#top-info{
	border: 1px solid #000;
	
	padding: 30px;
	opacity: .7;
	line-height: 1.7;
	margin: 0 auto;
	max-width: 700px;
	margin-top: 70px;
	@include sp{
		margin: 30px;
		margin-top: 60px;
		padding: 10px;	
	}//@sp
}

.top-info{
	background: #fffdc6;
	margin-top: 60px;
	border-radius: 4px;
	padding: 30px;
	text-align: center;
	.inner{
		display: inline-block;
		text-align: justify;
	}
	h2{
		font-size: 24px;letter-spacing: .1em;
		line-height: 1.5;
	}
	p{
		font-size: 16px;
		line-height: 1.7;
		margin-top: 1em;
		@include sp{
			font-size: 14*$px;	
		}//@sp
		@include pc{
			white-space: nowrap;
		}//@pc
	}
	
	p.cen{
		border:1px solid rgba(102, 102, 102, 0.47);
		text-align: center;
		margin-top: 0;
		padding: 15px 0;
		letter-spacing: .1em;
		strong{
			font-size: 1.2em;
			font-weight: bold;
		}
	}
	p.note{
		@include note;
	}
	&.holiday{
		background: #ffebeb;
		.date{
			background: #fff;
			text-align: center;
			padding: 10px 0;
			border-radius: 999px;
			letter-spacing: .1em;
		}
	}
}

/* !! - - -  */
/* !! ランキング */
ul.rank{
	display: flex;
	justify-content: space-between;	
	width: 100%;
	max-width: 950px;
	margin: 0 auto;
	margin-top: 50px;
	li{
		width: 200px;
		//background: #eee;
		.number{
			@include din;
			font-weight: 600;
			text-align: center;
			font-size: 36px;
			display: block;
			line-height: 1;
			margin-bottom: 23px;
		}
		.img{
			height: 200px;	
			background: #eee;
			position: relative;
			overflow: hidden;
			border: 1px solid #ddd;
			img{
				width: 100% !important;
				height: auto !important;
			}
			&:after{
				content: "";
				width: 28px;
				height: 28px;
				position: absolute;
				bottom: -14px;
				right: -14px;
				z-index: 1;
				background: #000;
				transform: rotate(45deg);
			}
		}
		.ttl{
			font-size: 16px;
			font-weight: bold;
			padding: 8px 0;
		}
		.price{
			font-size: 14px;
			text-align: right;
			position: relative;
			height: 20px;
			line-height: 1;
			margin-bottom: 10px;
			&.teiki{
				color: $ruge;
				b{
					display: inline-block;
					color: #fff;
					background: $ruge;
					line-height: 20px;
					padding: 0 5px;
					margin-right: 2px;
					vertical-align: top;
					font-size: 13px;
				}
				i{
					color: $ruge;
				}
			}
			span{
				position: absolute;
				left: 0;top: 50%;
				line-height: 1;
				font-size: 13px;
				color: $text;
				@include transform(translateY(-50%));
			}
			i{
				font-size: 1.36em;
				@include din;
				font-weight: 500;
			}
		}
		.detail{
			display: block;margin-top: 15px;
			border: 1px solid $lite-blue;
			text-align: center;
			color: $lite-blue;
			line-height: 28px;
			position: relative;
			font-weight: bold;
			&:after{
				@include anchor($lite-blue,6px,2px);
				left: auto;
				right: 10px;
			}
		}
		&:nth-of-type(1){
			.number{color: $gold;}
			.img:after{background: $gold;}
		}
		&:nth-of-type(2){
			.number{color: $silver;}
			.img:after{background: $silver;}
		}
		&:nth-of-type(3){
			.number{color: $copper;}
			.img:after{background: $copper;}
		}
		&:nth-of-type(4){
			.number{color: $bronze;}
			.img:after{background: $bronze;}
		}
	}
	
}



/* !! - - -  */
/* !! クロワール */

.btn2{
	background: $orange;
	display: block;
	height: 36px;
	@include flex-centering;
	border-radius: 18px;
	box-shadow: 0 2px 0px darken($orange,15%);
	color: #FFF !important;
	width: 160px;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: .1em;
	margin: 0 auto;
	margin-top: 15px !important;
	position: relative;
	transition: 100ms;
	&:hover{
		box-shadow: 0 1px 0px darken($orange,15%);
		top: 1px;
		color: #Fff;
	}
	&:after{
		
		content: "";
		@include anchor(#fff,10px,1px);//$color,$size,$Bw,$left,$right
	}
}

%cell{
	position: relative;
	transition:300ms $ease1;
	>a.cover{
		@include a-cover;
	}
	&.na{
		display: block !important;
	}
	&#probiotics12{
		.test & {
			display: block;
		}
		//display: none;
	}
	&.croire-coat{
		p._2{
			img{
				display: none;
			}
		}
		.price{
			@include pc{
				position: relative;
				top: -17px;
			}//@pc
		}
	}
	position: relative;
	#page_user_data &#probiotics12{
		.pb12{
			display: block ;
			padding-top: 12px;
			font-size: 13px;
		}
	}
	.pb12{
		display: none;
	}
	.inner{
		.pb12{
			//display: none;
		}
		position: relative;
		padding-left: 220px;
		width: auto;
		top: 50%;
		
		@include transform(translateY(-50%));
		.img{
			width: 180px;
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%));
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
	}
	width: 100%;
	height: 350px;
	display: inline-block;
	position: relative;
	padding: 40px;
	>img{
		width: 100% !important;
		height: auto !important;
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: auto !important;
	}
	
	.ttl{
		font-size: 24px;
		font-weight: bold;
		text-align: left;
		line-height: 1;
		i.cont{
			font-size: 0.67em;
			display: inline-block;
			margin-left: 10px;
			@include sp{
				display: block;
				margin-left: 0;
			}//@sp
		}
		span{
			font-size: 14px;
			margin: 8px 0;
			font-weight: normal;
			display: block;
			line-height: 1.5;
		}
	}
	
	.base_price p{
		font-size: 12px;
		@include sp{
			display: block !important;
			span{
				display: block;
				width: 100%;
				margin-bottom: 6px;
			}	
		}//sp
		font-weight: bold;
		vertical-align: middle;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		line-height: 1;
		margin: 20px 0;
		i{
			font-size: 22px;
			padding: 0 5px;
			@include sp{
				padding: 0;	
			}//sp
		}
	}
	
	ul{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		li{
			position: relative;
			width: 300px;
			padding-bottom: 15px;
			.hd{
				line-height: 30px !important;
				text-align: center;
				background: $lite-blue;
				color: #fff;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: .1em;
			}
			.txt{
				padding: 15px;
				font-size: 14px;
				font-weight: bold;
				color: $red-price;
			}
			a.btn2{
				
			}
			.price{
				padding:0px;
				white-space: nowrap;
				text-align: center;
				p{
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					white-space: nowrap;
					//height:30px;
					align-items: center;
					justify-content: center;
					height: 28px;
					img{
						margin-right: 10px;
						height: auto;
					}
				}
			}
			p._1{
				font-size: 13px;
				font-weight: bold;
				i{
					font-size: 1.5em;
				}
			}
			p._2{
				
				font-size: 18px;
				font-weight: bold;
				color: $red-price;
				vertical-align: top;
				position: relative;
				top: -3px;
				i{
					@include din;
					font-size: 40px;
				}
				img{
					height: auto;
					width: auto;
				}
			}
			
			&.teiki{
				border-bottom: 4px solid $lite-blue;
			}
			&.onestop,&.try{
				border-bottom: 4px solid #9fa0a0;
				.hd{
					background: #9fa0a0;
				}
			}
		}
	}
}

section#product{
	.slide{
		text-align: center;
		margin: 0 auto;
		margin-top: 50px;
		max-width: 960px;
		//background: #eee;
		//border: 1px solid #bbb;
		.slick-prev,.slick-next{
			position: absolute;
			z-index: 1;
			top: 50%;
			left: -50px;
			width: 80px;
			height: 80px;
			background: #000;
			border:none;
			font-size: 0;
			appearance: none;
			background: none;
			box-shadow: none;
			@include transform(translateY(-50%));
			&:after{
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				border: 7px solid #ccc;					
				border-width: 0 0 7px 7px;
				@include centering;
				@include transform(translate(-50%,-50%) rotate(45deg));
			}
		}
		.slick-next{
			left: auto;
			right: -50px;
			&:after{
				content: "";
				border-width: 7px 7px 0 0;
			}
		}
		.cell{
			@extend %cell;
			&:hover{
				@include transform(scale(1.02));
			}

			ul a{
				display: none !important;
			}
		}
	}
}



/* !! - - -  */
/* !! クロ割 */
#crowari{
	h2{
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	ul{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		max-width: 890px;
		justify-content: space-between;
		
		margin: 50px auto;
	}
}

.btn-more{
	margin: 0 auto;
	display: block;
	width: 300px;
	height: 50px;
	border-radius: 25px;
	border: 2px solid $lite-blue;
	text-align: center;
	color: $lite-blue;
	font-weight: bold;
	letter-spacing: .1em;
	font-size: 16px;
	line-height: 46px;
	position: relative;
	&:after{
		@include anchor($lite-blue,8px,2px);
		left: auto;
		right: 20px;
	}
}



/* !! - - -  */
/* !! マガジン */
ul.magazine{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	max-width: 960px;
	width: 100%;
	margin: 50px auto;
	position: relative;
	margin-bottom: 100px;
	&:after{
		width: 100vw;
		content: "";
		height: 250px;
		position: absolute;
		z-index: 0;
		bottom: -50px;
		left: 50%;
		@include transform(translateX(-50%));
		background: #F5F3F2;
	}
	li{
		width: 300px;
		position: relative;
		z-index: 1;
		background: #fff;
		padding-bottom: 30px;
		a{
			@include a-cover;
		}
		.img{
			height: auto;
			background: #eee;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.ttl{
			font-size: 16px;
			padding: 20px;
			line-height: 1.5;
			@include pfix(.5em);
			font-weight: bold;
			letter-spacing: .1em;
		}
		.date{
			position: absolute;
			bottom: 0;left: 0;
			white-space: nowrap;
			line-height: 1;
			font-size: 12px;
			margin: 20px;
		}
	}
}



/* !! - - -  */
/* !! ニュース */
ul.news{
	padding: 60px 100px;
	border: 1px solid #ccc;
	margin: 50px auto;
	li{
		position: relative;
		padding-left: 248px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 20px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	.date{
		font-size: 16px;
		position: absolute;
		top: 0;left: 0;
	}
	.cat{
		position: absolute;
		left: 125px;
		background: #eee;
		width: 100px;
		line-height: 30px;
		text-align: center;
	}
	.ttl{
		font-size: 18px;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 10px;
	}
	.desc{
		font-size: 14px;
		line-height: 1.6;
		@include pfix(.6em);
	}
}


/* !! - - -  */
/* !! ページフッター */
#footer{
	margin-top: 32px !important;
	.footer_logo_area{
		margin-top: 10px !important;
	}
}
#page-footer{
	display: flex;
	justify-content: space-between;
	max-width: 1118px;
	margin: 0 auto;
		margin-top: 100px;

	>div{
		width: 264px;
		border-bottom: 1px dotted #ccc;
		&.nb{
			border-bottom: 0 !important;
		}
		.hd{
			background: $lite-blue;
			text-align: center;
			line-height: 40px;
			color: #fff;font-weight: bold;
			position: relative;
			font-size: 18px;
			letter-spacing: .1em;
			position: relative;
			margin-bottom: 15px;
			&:after{
				content: "";
				width: 13px;
				height: 13px;
				position: absolute;
				left: 50%;
				bottom: 0;
				background: $lite-blue;
				@include transform(translate(-50%,50%) rotate(45deg));
			}
		}
		
	}
	ul{
		&.fix li{
			padding: 10px 0;
		}
		
		li{
			padding: 14px 0;
			border-bottom: 1px dotted #ccc;
			white-space: nowrap;
			&:last-of-type{
				border-bottom: 0;
			}
			&.bb{
				border-bottom: 1px dotted #ccc !important;
			}
			.red{
				color: $red;
				font-weight: bold;
			}
			&.rel1{
				box-sizing: content-box;
			}
			&.idx{
				width: 100%;
				
				p{
					font-size: 12px;
					width: 84px;
					text-align: center;
					padding: 10 0px;
					border: 1px dotted #ccc;
				}
			}
			a{
				display: inline-block;
			}
			i{
				background: $lite-blue;
				color: #fff;
				padding: 5px;
				border-radius: 4px;
				margin-right: 5px;
			}
			b{
				display: block;
				margin-bottom: 5px;
			}
			.img{
				width: 127px;
			}
			img{
				width: 100% !important;
				height: auto !important;
				display: block;
				
			}
		}
	}
}



.txt_bnr_area{
	display: none;
}

/* !!------------------------------------ */
/* !! 下層共通 */
main.page{
	
	h1.hd{
		margin: 60px 0 50px 0;
	}
	
	h2{
		height: 50px;
		border: 1px solid #3e3a39;
		border-width: 1px 0;
		line-height: 50px;
		margin-bottom: 50px;
		margin-top: 100px;
		&:first-of-type{
			margin-top: 0;
		}
	}
	
	h3{
		color: $lite-blue;
		border: 1px solid $lite-blue;
		text-align: center;
		max-width: 600px;
		margin: 50px auto;
		line-height: 50px;
		font-weight: normal;
  	}
}




/* !!------------------------------------ */
/* !! ご注文コースについて */
main.page.course{
	text-align: center;
	.img{
		text-align: center;
		.caption{
			line-height: 1.8;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			padding-top: 30px;
			@include pfix(.8em);
		}
	}
	
	ul.merit{
		display: block;
		max-width: 880px;
		margin: 0 auto;
		li{
			font-size: 32px;
			line-height: 1;
			position: relative;
			padding-left: 104px + 20;
			line-height: 1;
			margin-top: 70px;
			white-space: nowrap;
			text-align: left;
			>img{
				position: absolute;
				left: 0;top: 50%;
				@include transform(translateY(-50%));
				&.dec-r{
					left: auto;
					right: 0;
				}
			}
			
			.txt{
				display: inline-block;
				line-height: 1.3;
				strong{
					color: $pink;
				}
			}
			span{
				font-size: 0.56em;
				line-height: 1;
				display: block;
				margin-bottom: 10px;
			}
			.dec{
				display: inline-block;
				height: 60px;
				width: auto;
				vertical-align: top;
				position: relative;
				img{
					top: 50%;
					display: block;
					position: relative;
					@include transform(translateY(-50%));
					
				}
			}
		}
		
		& + .box{
			text-align: center;
			font-size: 24px;
			margin-top: 70px;
		}
	}
	
	.div{
		margin-top: 70px;
	}
	
	
	.box{
		text-align: justify;
		padding: 50px;
		border: 1px solid $text;
		max-width: 900px;
		margin: 20px auto;
		position: relative;
		overflow: hidden;
		h4{
			font-size: 32px;
			color: $lite-blue;
			line-height: 1;
			margin-bottom: 25px;
		}
		p{
			line-height: 1.75;
		}
		p.note{
			@include note;
		}
		&:after{
			content: "";
			width: 30px;height: 30px;
			position: absolute;
			top: 0;right: 0;
			background: $lite-blue;	
			z-index: 1;
			@include transform(translate(50%,-50%) rotate(45deg));				
		}
		
	}
	
	p.note{
		@include note;
		max-width: 900px;
		margin: 0 auto;
	}
	
	//!! 定期コースのお届けのタイミングは？
	.wrap{
		max-width: 900px;
		padding: 50px;
		margin: 0 auto;
		margin-top: -20px;
		background: rgba(240, 240, 240, 0.57);
		.img{
			display: block;
			background: #fff;
			min-height: 200px;
			margin: 15px 0;
		}
	}
	.label{
		width: 200px;
		height: 30px;
		line-height: 1;
		background: $lite-blue;
		color: #fff;
		@include flex-centering;
		font-size: 16px;
		font-weight: bold;
		border-radius: 4px;
	}
	p.label{
		margin-top: 20px;
		margin-bottom: 10px;
	}
	h4{
		margin: 15px 0;
		margin-top: 30px;
		text-align: left;
		.label{
			display: -webkit-inline-flex;
			display: -moz-inline-flex;
			display: -ms-inline-flex;
			display: -o-inline-flex;
			display: inline-flex;
			font-size: 16px;
			margin: 0 10px;
		}
	}


//!! - お試しコース
p.otamesi{
	font-size: 32px;
	text-align: center;
	
	strong{
		color: $pink !important;
		display: inline-block;
		line-height: 1;
		position: relative;
		&:before{
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;left: -10px;
			@include transform(rotate(-20deg) translateY(-50%));
			width: 9px;height: 53px;
			@include bg-img(url(../../img/course/deco@2x.png)); //$url,$size,$position,$repeat					
		}
		&:after{
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;right: -10px;
			@include transform(rotate(20deg) translateY(-50%));
			width: 9px;height: 53px;
			@include bg-img(url(../../img/course/deco@2x.png)); //$url,$size,$position,$repeat					
		}

	}
}
.desc{
	text-align: center;
	line-height: 1;
	margin-top: 50px;
}
	
	
}//main.page.course



/* !!------------------------------------ */
/* !! 良くあるご質問 */
main.FAQ{
	
	dl{
		max-width: 920px;
		margin: 0 auto;
		dt{
			margin-bottom: 30px;
			line-height: 70px;
			padding-left: 65px;
			font-size: 18px;
			font-weight: bold;
			color: $lite-blue;
			border: 1px solid $lite-blue;
			position: relative;
			cursor: pointer;
			&:before{
				content:"Q.";
				line-height: 1;
				position: absolute;
				left: 30px;
				top: 50%;
				margin-top: -5px;
				@include transform(translateY(-50%));
				@include din;
				font-weight: bold;
				font-size: 36px;
			}
			&:after{
				content: "＋";
				position: absolute;
				right: 30px;top: 50%;
				@include transform(translateY(-50%));
				line-height: 1;
				font-weight: bold;
				font-size: 24px;
			}
			&.opened{
				&:after{
					content: "ー" !important;
				}
			}
		}
		
		dd{
			padding-left: 70px;
			padding-right: 30px;
			margin: 30px 0;
			display: none;
			position: relative;
			
			p{
				font-size: 16px;
				line-height: 1.75;
			}
			&:before{
				content:"A.";
				color: #bbb;
				line-height: 1;
				position: absolute;
				left: 30px;
				top: 0;
				margin-top: -5px;
				//@include transform(translateY(-50%));
				@include din;
				font-weight: bold;
				font-size: 36px;
			}
		}
	}	
	
}//main.FAQ



/* !!------------------------------------ */
/* !! お支払いについて */

main.payment{
	
	.table-list{
		
		li{
			position: relative;
			padding: 30px;
			padding-right: 0;
			font-size: 16px;
			
			margin-bottom: 20px;
			padding-left: 264px + 30;
			line-height: 1.81;
			.hd{
				border: 1px solid #26B7BC;
				color: #26B7BC;
				font-weight: bold;
				padding: 5px 10px;
				margin-bottom: 15px;
			}
			p{
				line-height: 1.81;

			}
			h3{
				color: $text;
				font-weight: bold;
				letter-spacing: .1em;
				max-width: 100%;
				border: 0;
				padding: 0;
				padding-bottom: 5px !important;				
				line-height: 1;
				text-align: left;
				margin: 0;
				margin-bottom: 10px;
				border-bottom: 1px solid #ccc;
				margin-top: 20px;
				&:first-of-type{
					margin-top: 0;
				}
			}
			>span{
				display: block;
				padding: 30px;
				position: absolute;
				top: 0;left: 0;
				height: 100%;
				background: $lite-blue;
				width: 264px;
				color: #fff;
				font-weight: bold;
			}
		}
		
	}
	
	p.img{
		margin: 30px 0;
		&.cresit,&.convenience{
			padding-left: 30px;
		}
	}
	
	
}//main.payment



/* !!------------------------------------ */
/* !!  クロワールシリーズ一覧 */



main.product{
	//width: 950px;
	//display: flex;
	//justify-content: space-between;
	.main_visual{
		display: none;
		.item{
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
	}
	
	
	.mv{
		background: none;
		//background: #eee;
		height: 400px;
		position: relative;
		margin-top: -30px;
		p{
			width: 90% !important;
			//background: #000;
			height: 188px;
		}
	}
	
	#croire-list.croire{
		width: 1118px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		.cell{
			padding: 0 !important;
			.inner{
				z-index: 1;
				position: relative;
				//background-color: #F5F4F6;
				height: 100%;
				background-size: 100% auto !important;
				background-position: top right !important;
				.img{
					left: 30px !important;
				}
			}
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				background-color: #F5F4F6;
				position: absolute;
				z-index: 0;	
				top: 0;left: 0;		
			}
			&:before{
				display: none;
				content:"";
				position: absolute;
				bottom: -10px;
				right: -10px;
				width: 100%;
				height: 100%;
				z-index: 0;
				background-image: linear-gradient(135deg, #a4d7ef 25%, #7dc6e8 25%, #7dc6e8 50%, #a4d7ef 50%, #a4d7ef 75%, #7dc6e8 75%, #7dc6e8 100%);
				background-size: 14.14px 14.14px;
			}
		}
		
		//!! - クロワールプロバイオティクス12
		.probiotics12 {
			.inner{
				@include sp{
					&:after{
						content: "";
						background-size:200% auto !important;					
					}
							
				}//@sp
			}
			.teiki {
				.hd{
					position: relative;
					@include pc{
						padding-right: 65px;	
					}//@pc
					
					@include sp{
						padding-left: 40*$px;	
						white-space: nowrap;
					}//@sp
					&:after{
						content: "";
						display: block;
						width: 90px;
						height: 90px;
						position: absolute;
						top: 50%;
						right: -35px;
						@include transform(translateY(-50%));
						@include bg-img(url(../../product/img/pb12_label1@2x.png)); //$url,$size,$position,$repeat
						@include sp{
							width: 80*$px;
							height: 80*$px;
							left: -15px;
							right: auto;	
						}//@sp				
					}
				}
				.btn2{
					display: none;
				}
				.txt{
					visibility: hidden;
				}
			}
			.onestop{
				.hd{
					position: relative;
					@include pc{
						padding-right: 65px;	
					}//@pc
					
					@include sp{
						padding-left: 30*$px;	
					}//@sp
					&:after{
						content: "";
						content: "";
						display: block;
						width: 90px;
						height: 90px;
						position: absolute;
						top: 50%;
						right: -35px;
						@include transform(translateY(-50%));
						//@include bg-img(url(../../product/img/pb12_label2@2x.png)); //$url,$size,$position,$repeat	
						@include sp{
							width: 80*$px;
							height: 80*$px;
							left: -15px;
							right: auto;	
						}//@sp				
					}
				}
				}
		}
		.cell{
			@extend %cell;
			
			.ttl span{
				white-space: nowrap;
				br{
					//display: none;
				}
			}
			.inner{
				padding: 30px;
				padding-left: 240px !important;
				ul.idx{
					width: 100%;
					justify-content: space-between;
				}
			}
			
			.base_price p{
				margin: 10px 0;
				
			}
			
			@media screen and (min-width: 768px) {
				&.right{
					.inner{
						margin-left: 0 !important;
						padding-right: 240px !important;
						.img{
							left: auto !important;
							right: 30px !important;
						}
					}
					.ttl{
						text-align: right;
					}
					.base_price p{
						justify-content: flex-end;
						
					}
					ul.idx{
						margin-left: auto;
						margin-right: 0 !important;
					}
				}
				
			}
			
			
			order: 1;
			margin-bottom: 50px;
			a{
				text-align: center;
			}
			ul{
				max-width: 640px;
			}
			
		}
		.na{
			display: block !important;
		}
		//!! - 背景
		.probiotics12:after{
			background-color: #fff;
			@include bg-img(url(/img/croire/pb12-bg.jpg),auto 100%,right bottom); //$url,$size,$position,$repeat
		}
		.premiun-croire:after{
			@include bg-img(url(/img/croire/cloire@2x.jpg)); //$url,$size,$position,$repeat
		}
		.lutein:after{
			@include bg-img(url(/img/croire/lutein@2x.jpg),100%); //$url,$size,$position,$repeat
		}
		
		.pro:after{
			transform: rotateY(180deg);
			@include bg-img(url(/img/croire/eye@2x.jpg),auto 200%,top right); //$url,$size,$position,$repeat
		}
		
		.dhaepa:after{
			@include bg-img(url(/img/croire/dha@2x.jpg),50% auto,bottom right); //$url,$size,$position,$repeat
		}
		
		.na:after{
			transform: rotateY(180deg);
			@include bg-img(url(/img/croire/na@2x.jpg),auto 180%,bottom right); //$url,$size,$position,$repeat
		}
		
		.q10:after{
			@include bg-img(url(/img/croire/q10@2x.jpg),auto 150%,top right); //$url,$size,$position,$repeat
		}
		
		.gold:after{
			transform: rotateY(180deg);
			@include bg-img(url(/img/croire/gold@2x.jpg),auto 180%,center right); //$url,$size,$position,$repeat
		}
	
		.wahaha:after{
			@include bg-img(url(/img/croire/wahaha@2x.jpg),auto 280%,top right); //$url,$size,$position,$repeat
		}
		
		//!! クロワールコート
		.croire-coat{
			&:after{
				content: "";
				@include bg-img(url(../../img/croire/croiire-coat@2x.jpg)); //$url,$size,$position,$repeat					
			}
			@include pc{
				.idx{
					justify-content: flex-end !important;
					.onestop{
						height: 180px;
						.price,.btn2{
							top: -20px;
							position: relative;
						}
					}
				}	
			}//@pc
			p._2{
				img{
					display: none;
				}
			}

		}
		
	}//#croire-list
	
	
}//main.product



/* !! - - -  */
/* !! ポップアップ */

#shade{
	position:fixed !important;
	top: 0;left: 0;
	z-index: 10;
	width:110vw;
	height:140%;
	opacity: 0;
	pointer-events: none;
	transition: 300ms;
	background: rgba(0, 0, 0, 0.3);
	body.popup & {
		opacity: 1;
		pointer-events: auto;
	}
}

#popup{
	
	body.popup & {
		opacity: 1;
		pointer-events: auto;
		@include pc{
			@include transform(translate(-50%,20px));
			&.onestop{
				@include transform(translate(-50%,-50%));
			}
				
		}//@pc
	}
	
	&.na{
		display: block !important;
	}
	
	transition: 1000ms cubic-bezier(0.79, 0, 0.29, 0.99);
	transition-delay:300ms;
	opacity: 0;
	position: absolute;
	@include sp{
		position: fixed;	
	}//@sp
	pointer-events: none;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%,30px));
	&.onestop{
		@include pc{
			position: fixed;
			@include transform(translate(-50%,-48%));	
		}//@pc
	}
	max-width: 800px;
	width: 800px;
	padding: 50px 50px;
	background: #fff;
	z-index: 11;
	.close{
		position: absolute;
		cursor: pointer;
		top: 20px;
		right: 20px;
		width: 25px;
		height: 25px;
		@include bg-img(url(../img/close@2x.png)); //$url,$size,$position,$repeat
	}
	h2{
		color: $lite-blue;
		font-size: 22px;
		letter-spacing: .1em;
		line-height: 1;
		&:after{
			content: "";
			display: block;
			height: 7px;
			margin: 15px 0;	
			@include bg-img(url(../img/bg-strp@2x.png)); //$url,$size,$position,$repeat		
		}
	}
	
	ul.idx{
		font-size: 16px;
		li{
			text-align: center;
			@include flex-centering;
			width: 150px;
			height: 100px;
			border: 1px solid $lite-blue;
			border-radius: 8px;
			line-height: 1.6;
			color: $lite-blue;
		}
	}
	
	h3{
		line-height: 1;
		font-size: 16px;
		letter-spacing: .1em;
		padding-bottom: 10px;
		border-bottom: 1px solid $lite-blue;
		margin-top: 20px;
		i{
			color: $lite-blue;
			padding-right: 4px;
		}
		.pb12 {
			display: none;
			font-size: 12px;
			.test & {
				display: inline;
			}
			@include sp{
				.test & {
					display: block ;
				}
			}//@pc

		}
	}
	p{
		letter-spacing: 0;
		margin-top: 10px;
		font-size: 15px;
	}
	
	p.pb12{
		display: none;
		.test & {
			display: block;
		}
	}
	
	#teiki-link-btn{
		margin: 0 auto;
		margin-top: 40px;
		width: 400px;
		height: 58px;
		background: $lite-blue;
		font-size: 18px;
		letter-spacing: .1em;
		font-weight: bold;
		color: #fff;
		@include flex-centering;
		position: relative;
		transition: 100ms;
		box-shadow: 0 2px 0 darken($lite-blue,15%);
		&:hover{
			box-shadow: 0 1px 0 darken($lite-blue,15%);
			top: 1px;
		}
		&+.note{
			font-size: 13px !important;
			margin-top: 25px !important;
		}
	}
	.teiki,.onestop{
		display: none;
		&.on{
			display: block;
		}
	}
	&.probiotics12.onestop{
		._1 .hd{
			position: relative;
			
			@include sp{
				//margin-left: 10px !important;
				
			}//@sp
			&:after{
				content: "";
				display: block;
				width: 80px;
				height: 80px;
				position: absolute;
				top: 50%;
				right: -5px;
				@include transform(translate(0%,-50%));
				//@include bg-img(url(../../product/img/pb12_label2@2x.png)); //$url,$size,$position,$repeat
				@include sp{
					width: 80*$px;
					height: 80*$px;
					left: -10px;
					right: auto;	
				}//@sp				
			}
		}
	}
	.onestop{
		
		>div{
			border-bottom: 1px solid $gray2;
			margin-bottom: 20px;
			padding-bottom: 20px;
			p{
				color: $red-price;
				font-weight: bold;
				letter-spacing: .1em;
				font-size: 18px;
				font-weight: bold;
				color: $red-price;
				vertical-align: top;
				position: relative;
				top: -3px;
				img{
					display: block;
					width: 109px !important;
					margin-right: 10px;
				}
				i{
					@include din;
					font-size: 40px;
					
				}
				&.price{
					height: 36px;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					-webkit-flex-wrap: wrap;
					-moz-flex-wrap: wrap;
					-ms-flex-wrap: wrap;
					-o-flex-wrap: wrap;
					flex-wrap: wrap;
					align-items: center;
					justify-content: flex-start;
					span{
						display: -webkit-inline-flex;
						display: -moz-inline-flex;
						display: -ms-inline-flex;
						display: -o-inline-flex;
						display: inline-flex;
						align-items: center;
						height: 36px;
						
					}
					.price_1{
						align-self: flex-start !important;
					}
					.price_2,.price_3{
						position: relative;
						b{
							@include pc{
								position: absolute;
								left:-10px;top: 0;
								height: 36px;
								@include flex-centering;
								@include transform(translateX(-100%));	
							}//@pc
							
						}
					}
					.btn2{
						margin: 0 !important;
						margin-left: auto !important;
						margin-right: 0 !important;
					}
				}
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			&._1{
				border-bottom: 1px solid $pink2;
				.hd{
					background: $pink2;
				}
			}
			&._2{
				img{
					opacity: 0;
				}
			}
		}
		.hd{
			height: 30px;
			margin-bottom: 20px;
			background: $gray2;
			@include flex-centering;
			color: #FFF;
			letter-spacing: .1em;
			font-weight: 600;
			@include sp{
				display: block;
				height: auto !important;
				padding: 5px 10px;
				margin-left: 0 !important;
				.note{
					font-size: 12px;
				}
			}//@sp
			@include pc{
				display: -webkit-flex !important;
				display: -moz-flex !important;
				display: -ms-flex !important;
				display: -o-flex !important;
				display: flex !important;
				flex-direction: row;
				align-items: center !important;
				justify-content: center;
				.note{
					display: inline-block !important;
					font-size: 13px;
				}	
			}//@pc

		}
		
	}
	
}//#popup

/* !!------------------------------------ */
/* !! ログイン画面 */

//!! line

.col-sm-8.col-sm-offset-2{
	padding: 30px !important;
	padding-top: 0 !important;
	text-align: center !important;
}


/* !!------------------------------------ */
/* !! 商品一覧 */
#result_list_box--129,
#result_list_box--16,
//#result_list_box--132,
#result_list_box--135,
#result_list_box--137,
#result_list_box--138,
#result_list_box--139,
#result_list_box--140,
#result_list_box--141,
#result_list_box--142,
#result_list_box--143,
#result_list_box--145,
#result_list_box--146,
#result_list_box--144,
#result_list_box--148,
{
	
	display: none !important;
	
}


/* !!------------------------------------ */
/* !! 商品詳細画面 */

//!! PB12
.PB12_1{
	#detail_description_box__name{
		position: relative;
		&:after{
			content: "";
			display: block;
			width: 100px;
			height: 100px;
			position: absolute;
			top: 0;
			right: -15px;
			//@include transform(translate(-30%,-50%));
			@include bg-img(url(/product/img/pb12_label1@2x.png)); //$url,$size,$position,$repeat
			@include sp{
				width: 80*$px;
				height: 80*$px;
				top: -30px;
				right: -15px;
			}//@sp				
		}
	}
	#detail_description_box__sale_price{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;	
			@include pc{
				padding: 10px 0;
			}//@pc
		.price02_default{
			display: -webkit-inline-flex;
			display: -moz-inline-flex;
			display: -ms-inline-flex;
			display: -o-inline-flex;
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			@include sp{
				margin-right: 5px;
				margin-top: 15px;
			}//@sp
			&:before{
				content:"初回・2回目以降\A 30%OFF";
				white-space: pre;
				@include flex-centering;
				display: -webkit-inline-flex;
				display: -moz-inline-flex;
				display: -ms-inline-flex;
				display: -o-inline-flex;
				display: inline-flex;
				font-size: 12px;
				border: 2px solid $orange;
				text-align: center;
				padding: 0 10px;
				height: 40px;
				line-height: 1.2;
				margin-right: 10px;
			}
		}
	}
	#detail_cart_box{
		&:after{
			content: "※本品とその他クロワール商品を同梱してお届けする場合、\A佐川急便での配送となります。";
			white-space: pre;
			@include sp{
				font-size: 13px;
				white-space: normal;	
			}//@sp					
		}
	}
}

.PB12_2{
	#detail_description_box__name{
		position: relative;
		&:after{
			content: "";
			display: block;
			width: 100px;
			height: 100px;
			position: absolute;
			top: 0;
			right: -15px;
			//@include transform(translate(-30%,-50%));
			//@include bg-img(url(/product/img/pb12_label2@2x.png)); //$url,$size,$position,$repeat
			@include sp{
				width: 80*$px;
				height: 80*$px;
				top: -30px;
				right: -15px;
			}//@sp					
		}
	}
}


/* !! iframe */
iframe.detail{
	display: block;
	width: 100%;
}
	
	span.type{
		display: inline-block;
		
		border: 2px solid $blue;
		color: $blue;
		
		font-size: 16px ;
		@include sp{
			font-size: 14px;
		}//sp
		font-weight: 600;
		letter-spacing: .1em;
		padding: 5px 10px;
		margin-bottom: 10px;
		
	}

	#site-header{
		display: none !important;
	}

#detail_description_box__sale_point{
	display: none !important;
}

#summary_box__customer_point + #summary_box__customer_point{
	display: none !important;
}

/* !!------------------------------------ */
/* !! フッター */
#footer {
	#page-footer{
		max-width: 1118px;
		margin: 0 auto;
		margin-bottom:0px;
	}
}
.footer_logo_area{
	p.logo{
		text-align: center;
	}
	a{
		display: block;
		
		img{
			display: inline-block;
			width: 200px;
			height: auto;
			vertical-align: middle;
		}
		span{
			vertical-align: middle;
			font-size: 1.2rem;
			margin-left: 10px;
		}
	}
	.copyright{
		text-align: center !important;
	}
}

/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! 商品詳細 */

.price02_default.teiki{
	font-size: 2.8rem;
	display: block;
	&:before{
		content: "初回50%OFF ";
		font-size: 2.2rem;
	}
	&:after{
		content: "円(税抜)";
		font-size: 2.2rem;				
	}
}
.price02_default-s{
	display: block;
	font-size: 2.2rem !important;
}

/* !!------------------------------------ */
/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! ★商品単体ページ共通 */

//!! - slide
#item_photo_area{
	
	.slick-slide{
		img{
			max-height: 464px;
			width: auto !important;
		}
	}
	
}//#item_photo_area

ul.path.product{
	top: 0;
	position: relative;
	z-index: 2;
	height: 50px;
}



main.single{	
	>section{
		padding: 0 ;
	}
	margin-top: 0px;
	position: relative;
	z-index: 1;
	h2{
		padding: 10px 0;
		font-size: 24px;
		height: auto;
		padding-bottom: 10px;
		position: relative;
	}
	.mv{
		//background: #ccc;
		height: 400px;
		overflow: hidden;
		-webkit-background-size: cover;
		background-size: cover !important;
		.inner{
			//background: #ddd;
			position: relative;
			height: auto;
			max-width: 1200px;
			height: 260px;
			margin: 70px auto !important;
			
			.img{
				float: right;
				margin-right: 50px;
			}
		}
		
		.data{
			height: 260px;
			float: right;
			margin-right: 50px;
			position: relative;
		}
		
		h1{
			text-align: right;
			@include minchou;
			font-size: 2.4rem;
			font-weight: normal;
			@include pfix(.71em);
			line-height: 1.71;
			letter-spacing: .1em;
			position: relative;
			top: 82px;
		}
		.logo{
			position: absolute;
			bottom: 0;right: 0;
		}
	}
	
	
	.lead{
		padding: 100px 0;
		text-align: center;
		max-width: 1000px;
		margin: 0 auto;
		padding: 100px 0;
		h2{
			line-height: 1.7;
			font-size: 24px;
			padding-bottom: 10px;
		}
		p{
			text-align: center !important;
			@include pfix(.8em);
		}
	}
	
	
	section.about{
		overflow: hidden;
		
		background: #EAF6FD;
		.inner{
			padding: 0;
			max-width: 1000px;
			margin: 100px auto !important;
			
			h2.Q{
				line-height: 1.7;
				text-align: center;
				border-bottom: 1px solid $line;
				padding-bottom: 10px;
				margin-bottom: 0 !important;
			}
			p.A{
				padding-top: 20px;
				text-align: justify;
				@include pfix(.75em);
			}
		}		
		
	}//section.about
	
	h2.bd{
		line-height: 1.7;
		text-align: center !important;
		height: auto;
		border-bottom: 1px solid $line;
		padding: 10px 0;
		padding-bottom: 10px;
		margin-bottom: 0 !important;
		margin-top: 50px !important;
	}
	
	>section{
		.inner{
			max-width: 1000px;
			margin: 0 auto;
			padding: 100px 0;
			h2{
				line-height: 1.7;
				text-align: center;
				@include pfix(.75em);
				margin: 100px 0 50px 0;
				&:first-of-type{
					margin-top: 0;
				}
			}
		}
	}
	
	h3.border{
		max-width: 383px;
		line-height: 38px;
		border: 1px solid $text;
		text-align: center;
		border-radius: 20px;
		font-size: 2rem;
		font-weight: bold;
		margin: 50px auto;
	}
	
	
	//!! - イメージリスト
	ul.img-list{	
		font-size: 0;
		text-align: center;
		&._50{
			li{
				margin: 0 25px;
			}
		}
		li{
			width: 150px;
			margin: 0 15px;
			margin-bottom: 30px;
			display: inline-block;
			vertical-align: top;
			.img{
				width: 100%;
				height: 150px;
				overflow: hidden;
				background: #eee;
				border-radius: 50%;
				img{
					width: 100% !important;
					height: auto !important;
					display: block;
				}
			}
			span{
				text-align: center;
				display: block;
				margin-top: 10px;
				font-size: 1.6rem;
				//background: #eee;
				width: 200%;
				left:50%;
				letter-spacing: .05em;
				@include transform(translate(-50%,0));
				position: relative;
				b{
					display: block;
					font-size: 1.13em;
					i{
						font-size: 1.22em;
					}
				}
			}
		}
		
		&.cen{
			span{
				text-align: center !important;
			}
		}
		
		//!! - - 中サイズ
		&.mid{
			li{
				width: 195px;
				vertical-align: top;
				.img{
					height: auto;
				}
				span{
					width: 100% !important;
					left: 0;
					@include transform(translate(0));
					text-align: justify;
				}
			}
		}
		
		//!! - - 大サイズ
		&.lg{
			li{
				width: 250px;
				vertical-align: top;
				&:nth-last-child(-n+3){
					margin-bottom: 0;
				}
				.img{
					height: auto;
				}
				span{
					padding-top: 20px;
					margin-top: 20px;
					@include pfix(.7em);
				}
			}
		}
		
	}//ul.img-list
	
	
	ul.img-list2{
		
		display: flex;
		justify-content: space-between;
		li{
			width: calc((100% - 90px)/4);
			.img{
				background: #fff;
				height: 200px;
				position: relative;
				img{
					width: 100% !important;
					height: auto !important;
				}
				i{
					position: absolute;
					bottom: 0;left: 0;
					line-height: 22px;
					background: #FACD00;
					width: 110px;
					text-align: center;
					font-size: 1.6rem;
					z-index: 1;
				}
			}
			p{
				font-size: 1.6rem;
				b{
					display: block;
					font-size: 1.13em;
					line-height: 1;
					margin: 20px 0 10px 0;
				}
			}
			
		}
	}
	
	section.bg-GR{
		background: #EFEFEF;
		overflow: hidden;
		.inner{
			max-width: 1000px;
			margin: 0 auto;
		}
	}
	
	
	.note{
		font-size: 1.4rem;
	}
	
	
	
	table.nutrient{
		table-layout: fixed;
		width: 100% !important;
		height: auto !important;
		border-collapse: collapse;
		position: relative;
		caption{
			font-size: 1.6rem;
			letter-spacing: .1em;
			position: absolute;
			top: -2em;
			right: 0;
		}
		
		th,td{
			border: 1px solid $line;
			padding: 20px;
			font-size: 1.6rem !important;
		}
		th{
			background: #EAF6FD;
			width: 200px;
		}
		td{
			font-size: 1.4rem;
			text-align: right;
		}
		& + table.material{
			margin-top: 50px;
		}
		
		& + p.ar{
			font-size: 1.6rem;
			margin-top: 10px;
		}
	}
	
	table.material{
		margin-top: 100px;
		width: 100%;
		border-collapse: collapse;
		th,td{
			border: 1px solid $line;
			padding: 20px;
		}
		th{
			background: #EAF6FD;
			font-size: 2rem;
			width: 200px;
		}
		td{
			font-size: 1.4rem;
		}
	}
	
	p.hd{
		line-height: 50px;
		text-align: center;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: .1em;
		border-radius: 25px;
		&.box{
			border-radius: 0;
		}
		&.border{
			border: 1px solid $text;
		}
	}
	
	section .inner > div.box{
		//background: #eee;
		padding: 50px 0;
		border-bottom: 1px dotted #333;
	}

	div.img-left{
		position: relative;
		.img{
			width: 250px;
			height: 250px;
			margin-left: 50px;
			img{
				width: 100% !important;
				height: auto !important;
				display: block;
			}
		}
		&.block{
			.img{
				.caption{
					font-size: 1.8em;
					font-weight: bold;
					line-height: 1;
					line-height: 30px;
				}
				width: 50%;
				height: auto;
				margin-left: 0;
			}
			.txt{
				left: 50%;
				width: 50%;
				padding-left: 50px;
				margin-top: 15px;
			}
		}
		.txt{
			//background: #eee;
			position:absolute;
			text-align: left;
			left: 350px;
			top: 50%;
			@include transform(translateY(-50%));
			h2{
				text-align: left;
				margin: 0;
				line-height: 1;
				margin-bottom: 20px;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}

	div.img-right{
		position: relative;
		.img{
			padding-left: 50%;
			text-align: center;
		}
		.txt{
			//background: #eee;
			position:absolute;
			text-align: left;
			left: 0;
			width: 50%;
			top: 50%;
			@include transform(translateY(-50%));
			h3{
				text-align: left;
				margin: 0;
				line-height: 1;
				font-size: 2.4rem;
				letter-spacing: .1em;
				@include minchou;
				font-weight: normal;
				margin-bottom: 50px;
				border: none !important;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}


	
}


//!! 関連カテゴリ
#relative_category_box{
	display: none !important;
}//#relative_category_box

//!! 初回選択
#detail_cart_box__cart_class_category_id[data-type=定期購入]{
	opacity: 0;
	height: 0;
}

/* !!------------------------------------ */
/* !! ★プレミアムクロワール茶 */


main.premium-croire{
	
	.mv{
		@include bg-img(url(../../product/img/bg01.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.img.sod{
		text-align: center;
	}
	
	.desc{
		text-align: center;
		margin-top: 50px;
	}
	
	.result{
		position: relative;
		padding-right: 335px;
		.img{
			position: absolute;
			top: 0;right: 0;
			border: 1px solid $line;
		}
		.note{
			margin-top: 10px;
		}
		
		h3{
			color: #fff;
			line-height: 40px;
			background: #B81C22;
			font-size: 2rem;
			padding-left: 15px;
			margin: 50px 0 30px 0;
		}
		.red{
			color: #B81C22;
			font-size: 2rem;
			position: relative;
			padding-right: 240px;
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 20px;
			img{
				height: 50px;
				width: auto;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
	
	.idx{
		display: flex;
		justify-content: space-between;
	}
	
	
	
	
}//main.croire



/* !!------------------------------------ */
/* !! ★クロワールアイ・プロ */
main.croire-eye-pro{
	
	.mv{
		@include bg-img(url(../../product/img/bg02.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.idx{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&.rev{
			flex-direction: row-reverse;
		}
	}
	
	.graf{
		
		width: 900px;
		margin: 0 auto;
		margin-top: 50px;
		.txt{
			width: 410px;
			height: 238px;
			p{
				position: relative;
				top: 50%;
				margin-top: -20px;
				@include transform(translateY(-50%));
			}
		}
	}
	
	.img.ac{
		text-align: center;
	}
	
	p.ac{text-align: center;}
	
	
	
	h2.bd + p{
		padding-top: 30px;
		@include pfix(.7em);
	}
	
	img.KEMIN{
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin-right: 20px;
		//top: 50%;
		//display: inline-block;
	}
	
	.mt30{
		margin-top: 30px;
	}
	
	.idx.box{
		dl{
			max-width: 475px;
			//width: 100%;
			margin: 0;
			dt{
				position: relative;
				text-align: center;
				padding-top: 20px;
				span{
					position: absolute;
					top: 0;left: 0;
					font-weight: bold;
					font-size: 2.2rem;
				}
			}
			dd{
				font-size: 1.6rem;
				padding-top: 20px;
				@include pfix(.7em);
			}
		}
	}
	
	ul.img-list._200{
		width: 800px;
		margin: 0 auto;
		li{
			width: 200px !important;
			margin:0 25px;
			margin-bottom: 30px;
			&:nth-last-child(-n+3){
				margin-bottom: 0;
			}
			b{
				font-size: 2rem;
				font-weight: bold;
				line-height: 1;
				display: block;
				margin-bottom: 20px;
			}
			.img{
				height: auto;
			}
		}
	}
	
	ul.more-elem{
		font-size: 0;
		text-align: center;
		li{
			display: inline-block;
			letter-spacing: .1em;
			margin:0 10px;
			line-height: 60px;
			padding: 0 20px;
			min-width: 133px;
			text-align: center;
			font-size: 2rem;
			font-weight: bold;
		}
	}
	
	
}//main.croire-eye-pro




/* !!------------------------------------ */
/* !! ★クロワールアイゴールド */
main.croire-gold{
	
	.mv{
		@include bg-img(url(../../product/img/bg06.jpg)); 
		//$url,$size,$position,$repeat
	}

}

/* !!------------------------------------ */
/* !! ★クロワールアイ・ルテイン+ゼアキサンチンゼリー */
main.croire-eye-lutein{
	
	.mv{
		@include bg-img(url(../../product/img/bg02.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	ul.img-list.mid.cen{
		li{
			width: 213px;
			span{
				white-space: nowrap;
			}
		}
	}

}







/* !!------------------------------------ */
/* !! ★還元型コエンザイムQ10 */
main.Q10{
	
	.mv{
		@include bg-img(url(../../product/Q10/img/mv-bg.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	div.img-right.block{
		margin-top: 100px;
		.txt{
			padding-right: 50px;
		}
		h3{
			line-height: 1.75;
			@include pfix(.75em);
			padding-bottom: 50px;
			border: none !important;
		}
		h4{
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 20px;
		}
	}
	
	div.img-left.block{
		.txt{
			margin-top: 0;
		}
	}
	
	.img-list li{
		margin-bottom: 0 !important;
	}
	
	p.ac{
		text-align: center;
	}
	
	ul.img-list.food{
		max-width: 600px;
		margin: 0 auto;
		li{
			margin: 0 25px 50px 25px !important;
			&:nth-last-of-type(-n+3){
				margin-bottom: 0 !important;
			}
		}
	}
	
	p.hd{
		font-size: 2rem;
		line-height: 1;
		margin-bottom: 20px;
		strong{
			font-size: 1.2em;
			font-weight: 600;
			display: block;
			margin-bottom: 20px;
		}
	}
	
	ul.idx.vitamin{
		margin: 0 auto;
		text-align: center;
		margin-bottom: -25px;
		li{
			max-width: 415px;
			display: inline-block;
			width: 100%;
			height: 200px;
			position: relative;
			margin: 25px;
			border: 1px solid #fff;
			border-radius: 10px;
			p{
				text-align: center;
				font-size: 3rem;
				font-weight: 600;
				line-height:1.33;
				position: relative;
				color: #fff;
				top: 50%;
				@include pfix(.33em);
				@include transform(translateY(-50%));
				span{
					display: block;
					font-size: 0.5em;
				}
			}
		}
		
	}
	
	.sec.gyoushuku{
		$c1:#C30D23;
		margin: 100px 0;
		@include sp{
			margin: 50*$px 0;	
		}//@sp
		h2{
			border: none;
			padding: 0;
		}
		h3{
			color: $text;
			margin: 0;
			font-size: 24px;
			border: 0;
			border-bottom: 1px solid $text;
			padding-bottom: 10px;
			text-align: left;
			padding: 0;
			letter-spacing: .1em;
			@include minchou;
			max-width: 1000px;
			margin-bottom: 10px;
			img{
				display: block;
			}
			@include sp{
				font-size: 18px;
				img{
					width: 50px;
				}	
			}//@sp
		}
		p{
			font-size: 18px;
			strong{
				font-size: 22px;
				color: $c1;
				font-weight: 600;
			}
			@include sp{
				font-size: 16px;
				strong{
					font-size: 18px;
				}
			}//@sp
		}
		dl{
			font-size: 18px;
			-webkit-flex-wrap: wrap;
			-moz-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			-o-flex-wrap: wrap;
			flex-wrap: wrap;
			margin-top: 30px;
			dt{
				width: 290px;height: 56px;
				border: 1px solid $c1;
				border-radius: 4px;
				@include flex-centering;
				margin-top: 10px;
				font-weight: bold;
				color: $c1;
			}
			dd{
				margin-top: 10px;
				width: 680px;
				@include flex-centering;
				align-items: flex-start;
				text-align: justify;
			}
			@include sp{
				margin-top: 10px;
				font-size: 15*$px;
				dt{
					width: 120px;
					height: auto;
					text-align: center;
				}
				dd{
					width: calc(100% - 130px);
				}
			}//@sp
		}
	}
}



/* !!------------------------------------ */
/* !! 決済画面 */

h2.heading02{
	margin-bottom: 15px;
}

#confirm_box__quantity_edit_button{
	margin-top: 10px;
}

//!! ポイント表示
#cart_item__point_info{
	display: none;
}

//!! 定期購入の配送日時
select[id$=_shippingDeliveryDate] span {
	display: none;
}

body.type_teiki{
	#cart_item__info.message{
		display: none;
	}
}

//!! コンビニ決済
#page_epsilon_shopping_payment{
	
	#main{
		padding: 8px !important;
	}

	#payment_form_body {
		margin-bottom: 15px;
		dt{
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.radio{
			margin: 10px 0;
		}
		@include sp{
			input[id^=convenience_kana]{
				display: block;
				width: 100% !important;
				margin: 5px 0 10px 0;
			}
				
		}//sp
		input[id^=convenience_tel]{
			width: 70px;
		}
	}

	
}

//!! ボタン
.btn_group{
	margin-top: 15px;
	p{
		margin: 10px;
	}
}

/* !!------------------------------------ */
/* !! 最近チェックした商品 */
.item_gallery{
	display: none;
}


/* !!------------------------------------ */
/* !! マイページ */


/* !! - - -  */
/* !! ご注文履歴 */


#summary_box__add_point{
	display: none !important;
}
